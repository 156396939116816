@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';

.m-ln {
  text-align: left;
  color: $batcave;
  &__content-list {
    margin-bottom: 60px;
    @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
      margin-bottom: 30px;
    }
    .article-item {
      @include xy-cell-size($size: 8 of 12, $breakpoint: xxs);
      .m-ln { 
        &__provider {
          color: $pigeon;
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
          font-family: $font-family-sans-serif;
          margin-bottom: 8px;
          &-logo{
            float: left!important;
            margin: 3px 8px 0px 0px!important;
            width: 16px;
            height: 16px;
          }
        }
      
        &__excerpt {
          font-size: 14px;
          line-height: 24px; 
          margin-top: -6px;
          font-weight: 400;
          font-family: $font-family-sans-serif;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
            display: none;
          }
        }
      
        &__heading {
          margin-top: 8px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          font-family: $font-family-poppins;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &:hover {
            color: $picasso;
          }
        }
      }
    }
    .imgContainerEmpty {
      @include xy-cell-size($size: 10 of 12, $breakpoint: xxs);
    }
    
    .image_container {
      @include xy-cell-size($size: 4 of 12, $breakpoint: xxs);
      .m-ln {
        &__image {
          position: relative;
          overflow: hidden;
          border-radius: 16px;
          padding-top: 100%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  // Legal Compliant
  &__content-list.compliant {

    .m-ln__link {
      .grid-x.grid-margin-x {
        justify-content: space-between;
      }
    }
    
    .image_container  {
      max-width: 150px;

      .m-ln {
        &__image {
          border-radius: 12px;
        }
      }
    }
  }
  
  &__title {
    font-family: $font-family-poppins;
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    margin: 60px 0 8px 0px;
    &::before {
      content: "";
      display: inline-flex;
      height: 32px;
      background-color: $pineapple;
      width: 4px;
      border-radius: 8px;
      margin-right: 6px;
      vertical-align: middle;
    }
  }

  &__body {
    padding: 24px 0;
  }
  &__link {
    text-decoration: none;
    color: inherit;
  }

  &__content {
    &--left {
      margin-bottom: 40px;
    }
    &--right, &--center {
      .m-ln__heading {
        font-size: 0.875em;
        line-height: 1.428;
      }

      .m-ln__image {
        border-radius: 0.5em;
      }
    }
  }

  &__summary {
    font-family: $font-family-base;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667;
    margin: 0;
  }
  
  &--4, &--7, &--1 {
    margin-bottom: 20px;
    margin-top: 20px;
    .m-ln {
      &--content--left {
        margin-bottom: 40px;
      }

      &__content--right, &__content--center {
        .m-ln__link {
          display: block;
          margin-bottom: 44px;
        }

        .m-ln__image {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, xs) - 1px)) {
    &__content-list { 
      .article-item {
        @include xy-cell-size($size: 8 of 12, $breakpoint: xs);
        .m-ln {
          &__heading {
            -webkit-line-clamp: 2;
          }
        }
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 10 of 12, $breakpoint: xs);
      } 
      .image_container  {
        @include xy-cell-size($size: 4 of 12, $breakpoint: xs);
        .m-ln__image {
          padding-top: 75%;
        }
      }
    }

    // Legal Compliant
    &__content-list.compliant {

      .m-ln__link {
        .grid-x.grid-margin-x {
          justify-content: space-between;
        }
      }
      .image_container  {
        max-width: 180px;
        margin-top: 32px;
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, s) - 1px)) {
    &__content-list {
      .article-item {
        @include xy-cell-size($size: 4 of 7, $breakpoint: s);
        .m-ln {
          &__title {
            margin: 40px 0px 0px;
          }
          &__heading {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            -webkit-line-clamp: 3;
          }
        }
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: s);
      }
      .image_container  {
        @include xy-cell-size($size: 3 of 7, $breakpoint: s);
        .m-ln__image {
          padding-top: 77.3%;
        }
      }
    }

    // Legal Compliant
    &__content-list.compliant {
      .article-item {
        @include xy-cell-size($size: 5 of 7, $breakpoint: s);
        .m-ln__heading {
          margin-bottom: 25px;
        }
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: s);
      }
      .image_container  {
        @include xy-cell-size($size: 2 of 7, $breakpoint: s);  
        max-width: 150px;
        .m-ln__image {
          padding-top: 100%;
        }
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, m) - 1px)) {
    &__content-list {
      .article-item {
        @include xy-cell-size($size: 4 of 7, $breakpoint: m);
        .m-ln {
          &__excerpt { 
            -webkit-line-clamp: 3;
          }
          &__heading { 
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            -webkit-line-clamp: 3;
          }
        }
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: m);
      }
      .image_container  {
        @include xy-cell-size($size: 3 of 7, $breakpoint: m);
        .m-ln__image {
          padding-top: 75%;
        }
      }
    }

    // Legal Compliant
    &__content-list.compliant {
      .article-item {
        @include xy-cell-size($size: 5 of 7, $breakpoint: m);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: m);
      }
      .image_container  {
        @include xy-cell-size($size: 2 of 7, $breakpoint: m);  
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, l) - 1px)) {
    &__content-list {
      .article-item {
        @include xy-cell-size($size: 4 of 7, $breakpoint: l);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: l);
      }
      .image_container  {
        @include xy-cell-size($size: 3 of 7, $breakpoint: l);
        .m-ln__image {
          border-radius: 20px;
          padding-top: 77.3%;
        }
      }
    }

    // Legal Compliant
    &__content-list.compliant {
      .m-ln__link {
        .grid-x.grid-margin-x {
          justify-content: space-between;
        }
      }
      .article-item {
        @include xy-cell-size($size: 5 of 7, $breakpoint: l);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: l);
      }
      .image_container  {
        @include xy-cell-size($size: 2 of 7, $breakpoint: l); 
        max-width: 180px; 
        .m-ln__image {
          border-radius: 12px;
          padding-top: 75%;
        }
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, xl) - 1px)) {
    &__content-list {
      .article-item {
        @include xy-cell-size($size: 4 of 7, $breakpoint: xl);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: xl);
      }
      .image_container  {
        @include xy-cell-size($size: 3 of 7, $breakpoint: xl);
        .m-ln__image {
          padding-top: 66.5%;
        }
      }
    }

    // Legal Compliant
    &__content-list.compliant {
      .article-item {
        @include xy-cell-size($size: 5 of 7, $breakpoint: xl);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: xl);
      }
      .image_container  {
        @include xy-cell-size($size: 2 of 7, $breakpoint: xl);  
      }
    }
  }

  @media screen and (min-width: (map-get($breakpoints, xxl) - 1px)) {
    &__content-list {
      .article-item {
        @include xy-cell-size($size: 4 of 7, $breakpoint: xxl);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: xxl);
      }
      .image_container  {
        @include xy-cell-size($size: 3 of 7, $breakpoint: xxl);
      }
    }

    // Legal Compliant
    &__content-list.compliant {
      .article-item {
        @include xy-cell-size($size: 5 of 7, $breakpoint: xxl);
      }
      .imgContainerEmpty {
        @include xy-cell-size($size: 6 of 7, $breakpoint: xxl);
      }
      .image_container  {
        @include xy-cell-size($size: 2 of 7, $breakpoint: xxl);  
      }
    }
  }
}
