@import '../../../base/assets/scss/_base/config';
// Submodule SCSS
@import '../../../news/assets/scss/news';
@import '../../../stream/assets/scss/content_blocks';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

$observationsTransitionSpeed: 250ms;
.ie-11 {
  .m-weather .location-form__input {
    width: 200px;
  }
}

.display-hidden {
  display: none !important;
}

.default-btn {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  flex-direction: row;
  justify-content: center;
  text-wrap: nowrap;
  max-height: 44px;
  gap: 4px;
  height: 44px;
  border-radius: 32px;
  border: 1px solid $dirty_seagull;
  background: #FFF;
  margin-right: 12px;
  margin-bottom: 10px;

  @media all and (max-width: 1679px){
    // TODO: min-width to be confirmed with Sae
    height: 40px;

  }

  &__label {
    color: $backwater;
    text-align: center;
    font-family: $font-family-poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    white-space: nowrap;
    @media all and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, xl) - 1px)){
      // TODO: min-width to be confirmed with Sae
      font-size: 11px;
      line-height: 16px;

    }
  }

  svg.location-gear {
    height: 24px;
    width: 24px;
  }

  &__cross {
    background-color: rgba(235, 15, 41, 0.06);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-block;
    margin-left: 8px;

    svg {
      padding-top: 7px;
      padding-right: 3px;

      path {
        fill: $solo_cup !important;
      }
    }
  }

  &.primary:hover {
    background: $grey_hair;

    .default-btn__label {
      color: $picasso;
    }
  }

  &.default:hover {
    background: $grey_hair;
  }

  &.default.done {
    border: 0px;
    background-color: #0047FF14;
    margin-top: 0;
    span {
      color: $picasso;
    }

    svg {
      path {
        fill: $white;
      }
    }
    &:hover{
      background-color: $picasso;
      margin-top: 0;
      span {
        color: $white;
      }
    }
  }

  &.primary {
    svg {
      path {
        fill: $bob;
      }
    }
  }

  &.primary.active {
    border: 1px solid $accent_blue;
    background: rgba(0, 71, 255, 0.08);

    svg {
      path {
        fill: $accent_blue;
      }
    }

    span {
      color: $accent_blue;
    }
  }

  &.home {
    padding: 10px 16px 10px 13px;
  }
}

.default-btn.hasCrossBtn {
  padding: 6px 6px 6px 16px !important;

}

.location-form__container.wrapper {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 24px;

  .location-form-flex {
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    align-items: center;


    .location-form-container{
      display: flex;
      max-height: 64px;
      width: 100%;
    }

    .location-form-container.smartphone {
      justify-content: space-between;
    }

    .location-form-container.right-rail {
      width: 100%;
      margin-top: 0;
      padding-left: 0;

      .location-form-flex__heading {
        h3 {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 480px) {
      justify-content: space-between;
    }

    &__heading {

      .heading-wrapper {

        display: inline-flex;
        align-items: center;
        gap: 12px;
      }

      .toggle-wrapper {
        .location-form-flex__toggleable {
          margin-left: 0;
          margin-bottom: 0;
        }

        @media screen and (min-width: 801px) and (max-width: 1027px) {
          display: block;
        }

        display: none;
      }

      &--border {
        width: 4px;
        border-radius: 4px;
        height: 40px;
        background: $accent_yellow;
      }

      &--heading {
        text-align: start;
        color: $batcave;
        font-family:  $font-family-poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 100% */
        @media screen and (max-width: map-get($breakpoints, xs)) {
          margin: 0px;
        }

        @media screen and (min-width: 375px) and (max-width: 900px){
          font-weight: 800;
          letter-spacing: -0.6px;
        }
      }
    }

    &__form {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;

      .location-form {
        display: flex;
        max-width: 400px;
        width: 100%;
        padding: 8px 8px 8px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 32px;
        border: 1px solid $dirty_seagull;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10);

      }
      .location-form--hidden {
        display: none !important;
        button,input{
          display: none;
        }
      }


      &__input {

        border: none;
        background: transparent;
        border-radius: inherit;
        outline: none;
        width: 100%;
        color: $dolphin;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $font-family-poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
      }

      &__input.smartphone {
        font-size: 16px;
      }

      &__input::placeholder {
        color: $backwater;
        text-align: start;
        font-family: $font-family-poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }

      &__search-btn {
        width: 47px;
        height: 32px;
        flex-shrink: 0;
        background-color: #fff;
        border: none;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: $font-family-poppins;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 11px;
          height: 16px;
          svg {
            fill: $accent_blue;
          }
        }

        span.label {
          height: unset;
        }

      }

      &__search-btn.disabled {
        background-color: $marshmallow;

        span {
          color: inherit;
        }

      }

      .tooltiptext {
        width: 270px;
        height: 90px;
        display: none;
        background: $white;
        box-shadow: 0px 16px 20px 0px rgba(199, 205, 210, 0.4), 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        top: -50%;
        left: 20%;
        transition: opacity 0.3s;
        justify-content: space-between;
        .sad-cloud{
          align-self: center;
        }
        .body{
          .heading {
            color: $batcave;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $font-family-poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 153.846% */
            letter-spacing: -0.2px;
            width: 165px;
          }

          .content {
            color: $batcave;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $font-family-poppins;
            width: 165px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            margin-top: 4px;
          }
        }
        &-close{
          cursor: pointer;
        }
      }

      .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: $white transparent transparent transparent;
      }

      .suggestion-container, .home-suggestion-container {
        position: relative;
      }

      .location-form__suggestions, .location-form__suggestions_home {

        // border: 1px solid var(--greyscale-13-dirty-seagull, #E0E4E9);
        opacity: 0.94;
        background: $white;
        width: 99%;
        max-width: 396px;
        top: 0;
        position: absolute;
        flex-shrink: 0;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10);


        &--item {
          color: $backwater;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 200% */
          margin: 10px 10px;
        }

        &--item:hover {
          border-radius: 48px;
          background: rgba(0, 71, 255, 0.08);
        }
      }

    }

    &__form.w-0 {
      width: 0;
    }

    &__form:has(.location-form--hidden) {
      width: 0;
    }

    &__form:hover {
      .tooltiptext {
        display: flex;
        opacity: 1;
      }
    }

    &__gear {
      display: flex;
      align-items: center;
      margin-right: 0;

      svg path {
        fill: #687078
      }
    }

    &__toggleable {
      cursor: pointer;
      display: inline-flex;
      align-items: flex-end;
      gap: 6px;
      margin-left: 20px;
      margin-bottom: 5px;


      &__label {
        color: $backwater;
        font-family: $font-family-base;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 145.455% */
        margin-bottom: 1px;
      }
      &--centered{
        align-items: center;
        margin-bottom: 0;
        .location-form-flex__toggleable__label{
          margin: 0;
        }
      }

    }


    .addEditBtn-container {

      display: flex;
      align-items: flex-start;
      margin-top: 16px;
      margin-left: auto;

      #addEditBtnID {
        display: none;
        margin-right: var(--content-gutter-horizontal);
        border: none;
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
          display: none;
        }

        span.default-btn__label {
          display: none;

          @media screen and (min-width: map-get($breakpoints, s)) {
            display: inline-flex;
          }

          &.visible{
            display: inline-flex;
          }
          &.invisible{
            display: none;
          }
        }


        .configure_location_settings {
          display: flex;

          @media screen and (min-width: map-get($breakpoints, s)) {
            display: none;
          }

          &.visible{
            display: flex;
          }
          &.invisible{
            display: none;
          }
        }


        &:hover{
          background: #0047FF14;
          span{
            color: $picasso;
          }
        }
      }
      button#addEditBtnID.done {
        &:hover{
          background: $picasso;
          span{
            color: $white;
          }
        }
      }
    }

    .addEditBtn-container.smartphone {
      display: none;
    }

    &__toggleable:hover {

      svg {
        path {
          fill: $picasso;
        }
      }

      p {
        color: $picasso;
      }
    }


  }
  .location-form-flex--mobile {
    background-color: $white;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    .modal-content {
      display: flex;
      flex-direction: column;
      padding-left: var(--content-gutter-horizontal);
      margin: 80px 20px 0;

      .exit-btn {
        button {
          float: right;
          background-color: $picasso_light;
          border: none;
          span.default-btn__label {
            color: $picasso;
          }
        }
        button:hover {
          background-color: $picasso;
          span.default-btn__label {
            color: $white
          }

        }
      }

      .heading {
        margin-top: 8px;
        margin-bottom: 16px;
        color: $batcave;
        font-family: $font-family-poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      .location-form-flex__form:hover {
        .tooltiptext {
          height: auto;
          display: flex;
          opacity: 1;
          top: 8%;

          .heading {
            margin-bottom: 0;
          }
        }
      }

      .location-list {
        overflow: initial;
        margin-top: 32px;
        .locations {
          display: flex;
          flex-wrap: wrap;
          .default-btn {
            margin-right: 16px;
            margin-bottom: 20px;
          }
        }
      }

      .separator hr {
        background: $bob;
        height: 1px;
        width: 95%;
      }

      .home-heading {
        margin-top: 36px;
        margin-bottom: 16px;
        color: $batcave;
        font-family: $font-family-poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }

  }
}

.location-list-wrapper {
  .location-list {
    column-gap: 12px;
    margin-bottom: 26px;
    display: flex;
    justify-content: flex-start;
    overflow-y: scroll;

    @media screen and (max-width: 1028px) {
      max-width: 100%;
    }

    .locations {
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 600px) {
        flex-wrap: nowrap;
        $containerPadding: map-get($grid-container-padding, xxs);
        padding-left: calc($containerPadding / 2);
      }
    }
    .location-pill-list-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;

      .location-pill-list {
        display: flex;
        flex-direction: row;
      }
    }

    button#addEditBtnIDDupe {
      border: none;
      display: inline-flex;
      margin-right: 0;

      span.default-btn__label {
        display: none;
        @media screen and (min-width: map-get($breakpoints, s)) {
          display: inline-flex;
        }

        &.visible{
          display: inline-flex;
        }
        &.invisible{
          display: none;
        }
      }

      .configure_location_settings {
        display: flex;
        @media screen and (min-width: map-get($breakpoints, s)) {
          display: none;
        }

        &.visible{
          display: flex;
        }
        &.invisible{
          display: none;
        }
      }

      &:hover{
        background: #0047FF14;
        span{
          color: $picasso;
        }
      }
    }

    button#addEditBtnIDDupe.done {
      &:hover{
        background: $picasso;
        span{
          color: $white;
        }
      }
    }

    #addEditBtnID {
      max-height: 40px;
    }
  }

  .location-list::-webkit-scrollbar {
    display: none;
  }
}

.m-weather.right-rails {
  width: 100%;
  margin-top: 0;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid $dirty_seagull;
  padding: 16px 20px 24px;
  background: $white;
  margin-bottom: 0 !important;
  // padding-left: 20px;
  .location-form__container {
    .location-form-flex {
      .addEditBtn-container {
        display: none;
      }
    }
  }
}

.m-weather {
  font-family: $font-family-base;
  text-align: left;

  @media all and (min-width: 1281px) and (max-width: 1679px) {
    margin-top: 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    margin: 0 0 12px;
    color: $batcave;
  }

  .weather {
    &__unit {
      &--imperial {
        color: $dolphin;
        display: inline;
        &:hover {
          color: $dory;
        }
        &--aria-hidden-text {
          position: absolute ;
          width: 1px ;
          height: 1px ;
          padding: 0 ;
          margin: -1px ;
          overflow: hidden ;
          clip: rect(0,0,0,0) ;
          white-space: nowrap ;
          border: 0 ;
        }
        &--unit {
          display: none;
        }

        .weather-unit-c & {
          display: none !important;
        }
      }

      &--metric {
        color: $dolphin;
        display: none;
        &:hover {
          color: $dory;
        }

        &--unit {
          display: none;
          font-size: 30px;
          top: -10px;
          position: relative;
        }

        .weather-unit-c & {
          display: inline !important;
        }
      }
    }
  }

  &.location-form--hidden {
    .location-form__container {
      display: none;
    }

    .location__search-toggle {
      display: block;
    }

    .location-form-headline {
      display: none;
    }
  }

  .location-form {
    &__container {
      position: relative;
    }

    &__container.mb-12 {
      margin: 0 0 12px 0;
    }

    &__fieldset {
      border: 0;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
    }

    &__input {
      border: 1px solid $bob;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      outline: none;
      padding: 0 8px;
    }

    &__submit {
      background-color: $dory;
      border: 0;
      color: $white;
      font-size: 12px;
      font-weight: bold;
      padding: 12px 8px;
      width: 24%;

      &:hover {
        background-color: $dory-hover;
      }
    }

    &__submit-icon {
      background-color: $dory;
      border: 0;
      color: $white;
      height: 32px;
      padding: 7px;
      width: 30px;

      svg {
        fill: $white;
      }
    }

    &__suggestions {
      background-color: $grey-dark;
      color: $white;
      left: 2px;
      position: absolute;
      right: 2px;
      top: 100%;
      z-index: 9999;

      &--inner {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      &--item {
        border: 1px solid transparent;
        cursor: pointer;
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 1.285;
        padding: 4px 20px;
        text-align: left;

        &:hover {
          color: $dory;
        }
        &:focus {
          border-color: $dory;
          outline: none;
        }
        &:first-child {
          padding-top:8px !important;
        }
        &:last-child {
          padding-bottom:8px;
        }
      }
    }
  }

  img {
    float: right;
    margin: 0px 10px 0 0;
  }

  .location {
    color: $batcave;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;

    &__icon {
      display: inline-block;
      fill: $dolphin;
      margin-right: 4px;
    }

    &__container {
      color: $batcave;
      &--toggleable {
        background: transparent;
        border: 0;
        cursor: pointer;
        display: block;
        margin-bottom: 10px;
        padding: 0;
        text-align: left;
        width: 100%;
      }

      &:hover{
        color: $dory;
        .location{
          color: $dory;
          &__search-toggle{
            color: $dory;
          }
          &__icon{
            fill: $dory;
          }

        }
      }
    }

    .location-form--hidden & {
      margin-bottom: 0;
    }

    &__search-toggle {
      color: $dolphin;
      display: none;
      font-size: 12px;
      padding: 5px 0 10px 16px;
    }
  }

  .weather-details {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;

    &__link {
      color: $dolphin;
      text-decoration: none;
      display: inline-block;

      &:hover{
        span{
          color: $dory;
        }
        svg{
          fill: $dory;
        }

        .weather-details__full{
          color: $cobalt;
        }

      }
    }

    &__day {
      margin-bottom: 20px;

      &--title {
        background-color: #f6f6f6;
        color: $grey-medium;
        font-size: 12px;
        letter-spacing: 0.06em;
        margin-bottom: 16px;
        padding: 6px 10px;
        text-transform: uppercase;
      }

      &--inner {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
      }

      &--icon {
        display: block;
        float: left;
        line-height: 36px;
        margin-left: 0;
        position: relative;
        top: 0;
        width: 32px;

        svg {
          display: block;
          fill: $dolphin;
          height: 25px;
          width: 25px;
        }
      }

      &--temp {
        color: $grey-light;
        font-size: 30px;
      }

      &--temp-max,
      &--temp-min {
        font-size: 12px;

        &::before {
          display: inline-block;
          margin-right: 3px;
          vertical-align: middle;
          width: 12px;
        }
      }

      &--temp-max {
        margin-bottom: 6px;

        &::before {
          content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23999'%3e%3cpath d='M377.7 197.9l-34.2 33.8-75.8-76.6-.1 356.9-48.2-.1.4-357.7-76.6 75.8-33.9-34.2L243.9 62.6zM95.09999847.3h296.5v26.5h-296.5z'/%3e%3c/svg%3e");
        }
      }

      &--temp-min {
        &::before {
          content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23999'%3e%3cpath d='M109.1 314.5l34.2-33.9 75.8 76.6.1-356.9 48.1.1-.3 357.7 76.6-75.8 33.9 34.2-134.6 133.2zm-14.00000153 171h296.5V512h-296.5z'/%3e%3c/svg%3e");
        }
      }

      &:hover {
        color: $blue-hover;

        .weather-details__day--temp {
          color: $blue-hover;
        }
      }
    }

    &__full {
      color: $dory;
      font-size: 14px;
      font-weight: 700;
    }

    .missing-data{
      display: flex;
      justify-content: space-evenly;

      &__left{
        width: 40%;
        text-align: center;
        img{
          height: 90px;
          width: 100%;
          margin-top: 4%;
        }
        p{
          // margin: auto;
          font-family: $font-family-poppins;
          font-weight: 700;
          line-height: 16px;
          color: $batcave;
        }
      }
      &__right{
        width: 40%;
        padding-top: 6%;

        .missing-data-title{
          font-family: $font-family-poppins;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.4px;
          color: $batcave;
          min-height: 44px;
        }

        .missing-data-message{
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.4px;
          color: $ntk-article-background;
        }
      }
    }

    .weather-details__current-temp{
      .weather__unit--imperial {
        margin-left: -5px;
      }
    }
  }

  &#m-weather__news{
    .weather-details{
      padding: 0;
      &__container{
        @media screen and (min-width: 1281px){
          width: 100%;
        }
      }
    }
    .weather-details__full{
      margin-bottom: 15px;
      display: inline-block;
    }

    &.v2{
      margin-bottom: 64px;

      .weather-details__current--temp > span:first-child {
        .weather__unit--imperial, .weather__unit--metric {
          &--unit, &--degree {
            display: inline;
            font-size: 16px;
            top: -14px;
            position: relative;
            letter-spacing: 1px;
            color: rgba(18, 22, 25, 1);
            @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, xl)) {
              top: -20px;
            }
          }
        }
      }
    }
  }

  &#m-weather__minimal {
    height: 30px;
    margin: 0;
    position: relative;

    .weather-details {
      &__day {
        &--temp {
          font-size: 16px;
          padding-left: .25em;
          vertical-align: middle;
        }

        &--icon {
          display: block;
          float: left;
          line-height: 36px;
          margin-left: 0;
          position: relative;
          top: 3px;
          width: 17px;

          svg {
            display: block;
            fill: #333;
            height: 20px;
            width: 20px;
          }
        }

        &:hover {
          color: $dory;

          .weather-details__day--icon svg {
            fill: $dory;
          }

          .weather-details__day--temp,
          .weather__unit--imperial,
          .weather__unit--metric{
            color: $dory;
          }
        }
      }

      &__container {
        display: inline-block;
      }
    }

    .location   {
      color: black;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.3;
      text-transform: none;

      &__container.location__container--toggleable {
        display: flex;
        text-decoration: 0;
        align-items: center;
        margin-bottom: 0;
        margin-left: 4px;
        width: auto;

        &:hover {
          .location {
            opacity: 0.6;
          }
        }
      }
    }

    .location-form {
      height: 100%;

      &__fieldset {
        height: 100%;
      }

      &__container {
        bottom: 0;
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 160px;
      }

      &__submit {
        display: none;
      }

      &__suggestions {
        max-height: 105px;
        overflow-y: scroll;

        &--item {
          font-size: 12px;
          letter-spacing: 0;
          padding: 2px 5px;
          white-space: nowrap;
        }
      }
    }
  }

  .weather-details__banner {
    background-image: linear-gradient(90deg, #08eac7, #16a4dc);
    display: block;
    height: 40px;
    padding: 7px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;

    svg {
      fill: $white;
    }

    .weather-details__location {
      color: $white;
      font-size: 15px;
      letter-spacing: 1.25px;
      line-height: 18px;
    }

    .weather-details__cta {
      color: $blue;
      font-size: 10px;
      letter-spacing: 0.83px;
      position: relative;
      top: -2px;
    }

    .weather-details__temp-wrap {
      position: absolute;
      right: 20px;
      top: 0;
      display: flex;
      align-items: center;
    }

    .weather-details__day--temp {
      .weather {
        &__unit {
          &--imperial {
            color: $a11y-weather-dark-grey;
            display: inline;
            &:hover {
              color: $a11y-weather-hover;
            }
          }

          &--aria-hidden-text {
            position: absolute ;
            width: 1px ;
            height: 1px ;
            padding: 0 ;
            margin: -1px ;
            overflow: hidden ;
            clip: rect(0,0,0,0) ;
            white-space: nowrap ;
            border: 0 ;
          }

          &--metric {
            color: $a11y-weather-dark-grey;
            display: none;
            &:hover {
              color: $a11y-weather-hover;
            }
          }
        }
      }
    }
  }

  // Local
  .m-weather-card.cw_full {
    min-height: initial;
    height: inherit;
    padding: 32px 40px 20px;
    align-items: flex-start;

    .weather-details__container {
      width: 100% !important;
      .weather-details {
        flex-direction: row;
        margin-bottom: 20px;
        gap: 0;

        @media screen and (max-width: map-get($breakpoints, s)) {
          flex-direction: column;
          margin-bottom: 0;
          gap: 20px;
        }

        &__current {
          padding: 0;
          gap: 0;
          flex-grow: 1;
          &--temp {
            div {
              display: flex;
              flex-direction: row;
            }

            .weather-details__current__max-min {
              gap: 8px;
              max-width: 55%;
              @media screen  and (max-width: map-get($breakpoints, s)) {
                display: none;
              }
            }
            .weather__unit {
              &--imperial {
                span {
                  font-size: 60px;
                }
              }

              &--metric {
                span {
                  font-size: 60px;
                }
              }
            }

            .weather-details__details__max-min {
              &--max {
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                span {
                  font-size: 12px;
                }
              }

              &--min {
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                span {
                  font-size: 12px;
                }
              }
            }
          }

          &__weather {
            flex-direction: column;

            &--icon {
              img {
                width: 64px;
                height: 70px;
              }

              .desc {
                display: block;
                @media screen  and (max-width: map-get($breakpoints, s)){
                  display: none;
                }
              }
            }

            &--desc {
              font-size: 12px;
              display: none;

              @media screen and (max-width: map-get($breakpoints, s)) {
                display: inline-flex;
              }
            }
          }

          &__max-min {
            display: none;
            @media screen and (max-width: map-get($breakpoints, s)) {
              display: inline-flex;
            }
          }
        }

        &__forecast {
          padding-left: 0;
          padding-right: 0;
          gap: 0;
          flex-grow: 1;

          &__wrapper {
            &--time {
              width: 40px;
              color: $batcave;
            }

            &--temp {
              .temperature span{
                font-size: 12px;
                color: $battleship;
                font-weight: 500;
              }
            }
          }

          &__wrapper:last-child {
            @media screen and (max-width: 1360px) {
              display: none;
            }
          }
        }

      }

      .m-weather-card__separator {
        display: none;
      }

      .m-weather-card__bottom-nav {
        margin: 0
      }
    }
  }

  .m-weather-card {
    display: flex;
    max-height: 310px;
    height: auto;
    padding: 32px 24px 16px ;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: $white;
    box-shadow: 0px 16px 20px 0px rgba(199, 205, 210, 0.40), 0px 0px 16px 0px rgba(0, 0, 0, 0.05);


    @media screen and (min-width: 1680px) {
      padding: 32px 44px 16px ;
    }
    @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
      height: 190px;
    }
    @media screen and (max-width: map-get($breakpoints, s)) {
      align-items: normal;
    }

    .weather-details__container {
      font-family: $font-family-poppins;
      max-width: 100% !important;
      height: 100%;

      @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
        width: 100%;
      }

      @media screen and (max-width: 600px) {
        width: -webkit-fill-available;
      }

      div.fh {
        height: inherit;
        .flex {
          display: flex;
          flex-direction: column;
        }
      }


      .weather-details {
        display: flex;
        flex-direction: column;
        row-gap: 36px;


        @media screen and (min-width: map-get($breakpoints, m)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
          flex-direction: row;
          justify-content: center;
          column-gap: 40px;
        }
        @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, m) - 1px)) {
          flex-direction: row;
          justify-content: center;
          column-gap: 12px;
        }
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
          flex-direction: row;
          justify-content: center;
          column-gap: 40px;
        }

        &__current {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            justify-content: center;
            width: auto;
          }
          @media screen and (max-width: 800px) {
            padding: inherit;
          }
          .current_weather{
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 24px;

            @media screen and (min-width: (map-get($breakpoints, l))) and (max-width: (map-get($breakpoints, xl) - 1px)) {
              column-gap: 28px;
              width: auto;
            }
            @media screen and (min-width: (map-get($breakpoints, m))) and (max-width: (map-get($breakpoints, l) - 1px)) {
              column-gap: 32px;
              width: auto;
            }
            @media screen and (min-width: (map-get($breakpoints, s))) and (max-width: (map-get($breakpoints, m) - 1px)) {
              column-gap: 16px;
              width: auto;
            }
          }
          &--temp {
            span {
              color: $batcave;
              font-size: 44px;
              font-style: normal;
              font-weight: 500;

              @media screen and (max-width: map-get($breakpoints, xs)) {
                vertical-align: middle;
              }
              @media screen and (min-width: map-get($breakpoints, xl)) {
                vertical-align: middle;
              }
              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, xl)) {
                line-height: 56px;
              }
            }

            div {
              display: none;
              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
              }
            }
          }

          &__weather {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            flex-direction: row;
            width: 96px;

            @media screen and (min-width: (map-get($breakpoints, l))) {
              width: auto;
            }
            @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, l) - 1px)) {
              flex-direction: column;
              align-items: flex-start;
            }

            &--icon {
              display: flex;
              padding: 4px 5px;
              justify-content: center;
              align-items: flex-start;
              row-gap: 8px;
              flex-direction: column;

              img {
                flex-shrink: 0;
                margin-right: 0px;
                width: 56px;
                height: 56px;
              }

              .desc {
                color: $batcave;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                max-width: 100px;
                display: none;
                line-height: 16px; /* 123.077% */

                @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, l) - 1px)) {
                  display: block;
                  line-height: 14px;
                  font-size: 12px;
                  margin-left: 0.5rem;
                }
              }
            }

            &--desc {
              color: $batcave;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              max-width: 100px;
              line-height: 16px; /* 123.077% */

              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, l) - 1px)) {
                display: none;
              }
            }
          }
          &__max-min {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 11px;
            font-family: $font-family-poppins;
            color: $gotham;
            @media screen and (min-width: map-get($breakpoints, xl)) {
              align-items: flex-start;
            }
            @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
              display: none;
              text-align: right;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 133.333% */
            }
            @media screen and (min-width: map-get($breakpoints, xxs)) and (max-width: (map-get($breakpoints, xs) - 1px)) {
              align-items: flex-start;
            }

            span {
              color: $gotham;
              text-align: right;
              font-family: $font-family-poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 133.333% */
              padding-left: 2px;
              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                padding-left: 2px;
              }
              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, l) - 1px)) {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }

        &__forecast {
          display: flex;

          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          @media screen and (max-width: 480px) {
            padding: inherit;
          }

          @media screen and (min-width: map-get($breakpoints, m)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            column-gap: 32px;
          }
          @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, m) - 1px)) {
            column-gap: 16px;
          }
          @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
            column-gap: 28px;
          }


          &__wrapper {
            display: flex;
            width: 47px;
            justify-content: center;
            align-content: flex-start;
            gap: 10px 8px;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;

            &--time {
              color: $backwater;
              display: flex;
              width: 42px;
              height: 16px;
              flex-direction: column;
              justify-content: center;
              flex-shrink: 0;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px; /* 116.667% */

              @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                color: $batcave;
                font-size: 11px;
              }
            }

            &--icon {
              display: block;

              img {
                margin: 0;
                width: 44px;
                height: 44px;

                @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                  width: 36px;
                  height: 36px;
                }
              }
            }

            &--temp {
              display: flex;
              flex-direction: row;

              .temperature {
                font-weight: 600;
              }

              .precipitation {
                font-weight: 400;
              }


              .temperature , .precipitation {
                color: $batcave;
                font-size: 12px;
                font-style: normal;
                line-height: normal;
                padding-left: 4px;
                padding-right: 4px;
                white-space: nowrap;

                @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                  font-size: 11px;
                }
              }

              .temperature span {
                font-size: 12px;
                color: $batcave;
                text-decoration: none;

                @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
                  font-size: 11px;
                  color: $battleship;
                }
              }
            }
          }
        }
      }

      .right-rail {
        .weather-details--fallback {
          margin-left: 0;

          .icon img{
            width: 96px;
            height: 96px;
          }

          .weather-default-info {
            .heading {
              font-size: 16px;
            }

            .sub-heading {
              font-size: 12px;
            }
          }
        }
        .bottom-container {
          .empty-weather {
            font-size: 13px;
          }
        }
      }

      .weather-details--fallback{

        padding: 0 10px;
        flex: 1;
        display: flex;
        border-radius: 20px;
        background: $marshmallow;
        align-items: center;
        gap: 36px;
        margin-left: 10px;
        margin-right: 10px;



        .icon-container {

          .icon {

            img {
              margin: inherit;
            }
          }


          .label {
            color: $batcave;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: -0.4px;
          }
        }

        .weather-default-info {
          .heading {
            color: $batcave;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.4px;
          }

          .sub-heading {
            margin-top: 16px;
            color: $charcoal;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.4px;
          }
        }
      }

      .bottom-container {
        display: flex;
        gap: 48px;
        margin-top: 12px;

        @media screen and (min-width: 1366px) and (max-width: map-get($breakpoints, xl)) {
          justify-content: space-evenly;
          gap: 28px;
        }

        @media screen and (min-width: map-get($breakpoints, m)) and (max-width: 1365px) {
          justify-content: space-evenly;
          gap: 40px;
        }

        @media screen and (max-width: 800px) {
          gap: 30px;
        }

        div {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          .empty-weather {
            text-wrap: nowrap;
            color: $batcave;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            white-space: pre-wrap;
          }
        }

      }

    }

    &__separator-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__separator-wrapper.fallback {
      display: none;
    }

    &__separator {
      min-width: 100%;
      height: 1px;
      background: $dirty_seagull;
      opacity: 0.3;
      margin: 28px 0 12px;

      @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
        margin: 20px 0 18px;
      }
      @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
        margin: 14px 0 12px;
      }
    }

    &__bottom-nav {
      display: flex;
      align-items: center;
      align-self: stretch;
      margin: 13px 0 8px 0;
      padding-top: 15px ;
      justify-content: space-between;
      border-top: 1px solid $dirty_seagull;

      @media screen and (max-width: 480px) {
        margin: 13px 0 8px 0;
      }

      @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
        margin: 13px 0 8px 0;
      }


      img {
        margin: 0;
      }

      a {
        text-decoration: none;
        display: flex;
        color: $backwater;
        font-size: 12px;
        font-family: $font-family-base;
        font-weight: 500;
        line-height: 16px; /* 133.333% */

        svg {
          width: 8px;
          height: 17px;
          margin-left: 6px;
          path {
            fill: $backwater;
          }
        }

      }
    }

    &__bottom-nav.fallback {
      display: none;
    }
  }
  // right-rail
  .m-weather-card.right-rail {
    box-shadow: none;
    padding: 0;
    height: initial;

    .weather-details__container {
      width: -webkit-fill-available;
      .weather-details {
        max-height: inherit;
        flex-direction: column;
        &__current{
          padding: 0;
          justify-content: space-evenly;

          &--temp{
            margin-right: 24px;

            @media screen and (max-width: 753px) {
              flex: 1 1 auto;
              display: flex;
              justify-content: center;

            }

            div {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              @media screen and (max-width: 753px) {
                display: none;
              }
            }

            span.weather__unit--imperial span, span.weather__unit--metric span {
              font-size: 40px;
              font-family: $font-family-poppins;
            }
            .weather-details__current__max-min {
              span.weather__unit--imperial span, span.weather__unit--metric span {
                font-size: 12px;
                @media screen and (max-width: 753px) {
                  display: none;
                }
              }
            }

          }


          &__max-min {
            display: none;
            @media screen and (max-width: 753px) {
              display: inline-flex;
              flex: 1 1 auto;
            }
          }

          &__weather {
            &--icon img{
              width: 55px;
              height: 55px;
            }

            &--desc {
              font-size: 12px;
              display: inherit;
            }
          }
        }

        &__forecast {
          padding: 0;
          gap: 36px;
          justify-content: space-around;

          &__wrapper {
            &--temp {
              .temperature span, .precipitation {
                font-size: 12px;
                color: $battleship;
                font-weight: 500;
              }
            }
          }
        }
        .m-weather-card__bottom-nav {
          margin: 0;
          gap: 64px;
          a span {
            font-family: $font-family-poppins;
            color: $picasso;
          }
        }

      }
      .m-weather-card__separator-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  .m-weather-card.fallback {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    min-width: inherit;
    max-height: inherit;
    height: inherit;

    .weather-details__container {
      width: 100% !important;
    }
  }

  .m-weather-card:has(.weather-details--fallback) {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    min-width: inherit;
    max-height: inherit;
    height: inherit;

    .weather-details__container {
      width: 100% !important;
    }
  }

  .m-weather-card.right-rail:has(.weather-details--fallback) {
    margin-bottom: 20px;

    .weather-details--fallback{
      margin-right: 0;
    }
  }


  &.m-weather__channel {
    background-color: $white;
    display: block;
    min-height: 469px;
    padding: 28px 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
    img{
      float: none;
    }
    @media screen and (min-width: 1281px) {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 16px 20px rgba(199, 205, 210, 0.4);
      border-radius: 28px;
    }

    .location-form-headline {
      color: $a11y-weather-dark-grey;
      font-family: $font-family-base;
      font-size: 30px;
      line-height: 33px;
      margin-left: 20px;
      padding-top: 50px;
      text-align: left;
      text-transform: none;

      @media screen and (max-width: 1037px) {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
      }

      @media screen and (max-width: 899px) {
        width: 80%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    .hideHeadline {
      display: none;
    }

    .location__container.showLocation {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    &.location-form--hidden {
      .location__container--toggleable {
        visibility:visible;
      }
    }

    .location__container {
      display: none;

      &--toggleable {
        display: flex;
        visibility: hidden;
        text-transform: none;
        width: auto;
        align-items: center;
        margin-bottom: 0px;
      }

      .temp-format-switcher {
        display: flex;
        align-items: center;
        justify-content: right;
        flex-wrap: wrap;
        position: relative;
        img {
          margin: 0 10px;
          @media screen and (max-width: 480px) {
            position: absolute;
            top:30px;
          }
        }

        .temp-format-wrapper{
          display: flex;
          border-radius: 22px;
          background-color: $grey-hair;
          height: 28px;
          cursor: pointer;
          font-size: 12px;
        }

        .weather-unit-f & .fahrenheit,  .weather-unit-c & .celsius {
          border-radius: 22px;
          border: 1px solid $dolphin;
          background-color: #fff;
          font-weight: 700;
        }

        .celsius,
        .fahrenheit {
          color: $inkwell !important;
          width: 44px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          border: none;
          border-radius: 22px;
          background-color: $grey-hair;
        }
      }
    }

    .location {
      font-family: $font-family-poppins;
      color: $batcave;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      letter-spacing: 0;
      @media screen and (min-width: 1281px) {
        font-size: 20px;
      }
    }
    .location-change {
      display: none;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      color: $dory;
      padding-left: 24px;
      font-family:$font-family-base;
      @media screen and (min-width: 1281px) {
        display: inline-block;
      }
    }
    .location--name {
      @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
        color: $dory
      }
    }

    .location-form__container.weather-location-toggle {
      margin-left: 0;
      position: absolute;
      top: 27px;
      height: 40px;
      margin-bottom: 14px;

      .location-form__fieldset {
        background-color: $white;
        border-radius: 42px;
        padding-left: 20px;
      }

      .location-form__submit-icon {
        background-color: $white;
        margin: 2px 13px;
        padding:11px 7px;
        svg {
          fill: $gray-base;
        }
      }

      .location-form__input {
        border: none;
        color: $dolphin;
        font-size: 16px;
        line-height: 20px;
        height: 38px;
        @media screen and (max-width: 480px) {
          height: 41px;
        }
      }

      .location-form__suggestions {
        background-color: $white;
        color: $batcave;
        border-radius: 12px;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
        margin-top: 8px;
      }

      @media screen and (max-width: 1037px) {
        width: 60%;
      }

      @media screen and (max-width: 899px) {
        width: calc(100% - 40px);
      }

      @media screen and (max-width: 480px) {
        width: calc(100% - 20px);
      }
    }

    .location-form {
      &__container {
        margin-left: 20px;
        width: 400px;

        @media screen and (max-width: 1037px) {
          margin-left: auto;
          margin-right: auto;
          width: 70%;
        }

        @media screen and (max-width: 899px) {
          width: 80%;
        }

        @media screen and (max-width: 480px) {
          width: calc(100% - 20px);
        }
      }

      &__input {
        border: none;
        color: $dolphin;
        font-size: 16px;
        line-height: 20px;
        height: 40px;
      }

      &__fieldset {
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 42px;
        background-color: $white;
        padding-left: 20px;
      }

      &__submit-icon {
        background-color: $white;
        margin: 2px 13px;
        padding:11px 7px;
        svg {
          fill: $gray-base;
        }
      }

      &__suggestions {
        background-color: $white;
        color: $batcave;
        border-radius: 12px;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
        margin: 8px 0px 12px;
        &--item {
          text-transform: none;
          padding-top:5px;
          &:focus {
            border:none
          }
        }
      }
    }
    .weather-details, .hours-wrapper, .days-wrapper {
      .weather {
        &__unit {
          &--imperial {
            display: inline;
            color: $batcave;
            font-weight: 600;
          }
          &--aria-hidden-text {
            position: absolute ;
            width: 1px ;
            height: 1px ;
            padding: 0 ;
            margin: -1px ;
            overflow: hidden ;
            clip: rect(0,0,0,0) ;
            white-space: nowrap ;
            border: 0 ;
          }
          &--metric {
            display: none;
            color: $batcave;
            font-weight: 600;

            @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
              font-weight: 500;
            }
          }
        }
      }
      &__current{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:50%;

        img {
          width: 88px;
          height: 100px;
          float: none;
          margin: 0px;
          @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            width: 68px;
            height: 96px;
          }
        }
        img.v2 {
          width: 100px;
          height: 100px;
          margin-bottom: 16px;
        }

        &__desc {
          font-family: $font-family-poppins;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.4px;
          text-transform: capitalize;

          @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            font-size: 14px;
            line-height: 12px;
          }
        }
      }
      &__today{
        display: flex;
        width:50%;
        flex-direction: column;
        align-items: center;
        &__temp {
          font-size: 72px;
          margin: 0;
          line-height: 96px;
          font-family: $font-family-poppins;
          @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
            font-size: 60px;
          }
        }

        &__details{
          font-family: $font-family-base;
          font-size: 16px;
          font-weight: 600;
          display: flex;
          flex-wrap: wrap;
          @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            font-weight: 500;
          }
          > div {
            display: flex;
            margin-right: 0.5rem;
            align-items: baseline;
          }
          svg {
            margin-right: 0.2rem;
            fill:$gandalf;
          }
        }
      }

      &__basic {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        @media screen and (min-width: 1281px) {
          width: 50%;
        }
      }
      &__additional-info {
        color: $batcave;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        border-left: 1px solid $dirty_seagull;
        display: flex;
        padding-left: 24px;
        flex-wrap: wrap;
        width: 50%;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
          border-left: none;
          border-top: 1px solid $dirty_seagull;
          padding-top: 10px;
          margin: 24px 56px 0px;
          width: auto;
          padding-left: 0px;
        }
        @media screen and (max-width: 480px) {
          width: auto;
          margin: 24px 5px 0px;
          padding-left: 0px;
        }
        .label {
          font-size: 10px;
          line-height: 20px;
          font-weight: 600;
          text-transform: uppercase;
          color: $battleship;
          @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
            font-weight: 500;
          }
        }
        .flex-col {
          display: flex;
          justify-content: center;
          align-items: baseline;
          flex-direction: column;
          box-sizing:border-box;
          width: 21%;
          font-weight: 700;
          @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
            padding: 20px 0px;
          }
        }
      }

      &__additional-info.v2 {
        border-left: none
      }
    }
    .weather-details{
      padding:40px 0px;
      border-radius: 20px;
      margin-bottom: 44px;
      background-color: $white;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 16px 20px rgba(199, 205, 210, 0.4);
      @media screen and (min-width: 1281px) {

        background-color: $marshmallow;
        box-shadow: none;
        margin-bottom: 28px;
      }

      @media screen and (max-width: 1280px) {
        padding: 40px 0 0;
      }
    }


    .forecast-wrapper {
      min-height: 180px;
      @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
        min-height: 5px;
      }
    }

    .forecast-switcher-wrapper{
      justify-content: center;
      display: flex;
      width: calc(100% - 420px);
      @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
        width: 100%;
      }
      @media screen  and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
        width: calc(100% - 268px);
      }
      @media screen  and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
        width: calc(100% - 287px);
      }
    }

    .forecast-switcher {
      display: flex;
      border-radius: 22px;
      background-color: $grey-hair;
      height: 36px;
      cursor: pointer;
      width: 200px;
      margin-bottom: 32px;
      button {
        color: #1d2228!important;
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        font-size: 12px;
        line-height: 28px;
        font-weight: 400;
        &.selected {
          border-radius: 22px;
          border: 1px solid #6e7780;
          background-color: #fff;
          font-weight: 700;
        }
      }
    }

    .hours-wrapper,
    .days-wrapper {
      width: calc(100% - 440px);
      margin: 0px 30px;
      font-size: 16px;
      @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
        margin: 0 40px;
        width: calc(100% - 370px);
      }

      @media screen and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
        margin: 0 50px;
        width: calc(100% - 404px);
      }
      @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
        width:90%;
        margin: 0px auto;
      }
      @media screen and (max-width: 480px) {
        width:100%
      }
      .glide {
        display: none;
        .glide__slides {
          touch-action: auto;
          display: flex;
          margin: 0;

          .glide__slide {
            display: grid;
            text-align: center;
            width: 100%;
            color: $batcave;

            .time {
              font-size: 14px;
              line-height: 16px;
              font-weight: 500;
              margin-bottom: 22px;
              text-transform: capitalize;
            }

            .precipitation {
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              margin-top: 16px;
              svg {
                fill:$gandalf;
                margin:0px 3px -1px;
              }
            }

            .temp,
            .max-temp,
            .min-temp {
              line-height: 24px;
              color: $batcave;
              margin-top: 22px;
              font-weight: 600;
            }

            img {
              height: 36px;
              margin: 0 auto;
            }
          }
        }

        .glide__arrows {
          [disabled] {
            opacity: 0.5;
          }
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);

          .glide__arrow {
            background-color: #fff;
            border: none;
            border-radius: 20px;
            display: inherit;
            font-size: 20px;
            font-weight: bold;
            height: 40px;
            position: absolute;
            top: 50%;
            width: 40px;

            &--prev{
              left: -30px;
            }
            &--next{
              right: -30px;
            }

            @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
              &--prev{
                left: -40px;
              }
              &--next{
                right: -40px;
              }
            }

            @media screen and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
              &--prev{
                left: -50px;
              }
              &--next{
                right: -50px;
              }
            }

            svg {
              fill:$batcave
            }
          }
          @media screen and (max-width: 480px) {
            display: none;
          }
        }
        .hideArrows {
          display: none;
        }
      }
    }
    .days-wrapper {
      .weather {
        &__unit--imperial, &__unit--metric {
          padding-left: 4px;
        }
        &__unit--aria-hidden-text {
          position: absolute ;
          width: 1px ;
          height: 1px ;
          padding: 0 ;
          margin: -1px ;
          overflow: hidden ;
          clip: rect(0,0,0,0) ;
          white-space: nowrap ;
          border: 0 ;
        }
      }
      .temp{
        font-size: 14px;
      }
    }
    .hourlyForecast.selected,
    .dailyForecast.selected {
      display: flex;
    }

    .video-wrapper {
      height: 213px;
      left: auto;
      position: absolute;
      right: 20px;
      top: 330px;
      width: 360px;

      .video-player, .m-video-wafer__player--container {
        height: 213px;
        position: relative;
        width: 360px;

      }

      @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px)) {
        width: 268px;
        height: 157px;
        top: 385px;

        .video-player, .m-video-wafer__player--container {
          height: 157px;
          width: 268px;

        }
      }

      @media screen and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
        width: 287px;
        height: 169px;
        top: 350px;

        .video-player, .m-video-wafer__player--container {
          width: 287px;
          height: 169px;
        }

      }
    }

    .hideVideo {
      opacity: 0;
    }
  }

  &.m-weather__channel.v2 {
    padding: 0px;
    .weather-details.v2 {
      background-color: $white;
      margin-bottom: 0;

      .weather-details {
        &__today {
          span.weather__unit {
            &--imperial {
              display: inline-block;
              margin-bottom: 16px;
            }
          }
        }

        &__additional-info {

          span.label {
            color: $backwater;
          }
        }
      }

    }

    .separator-wrapper hr{
      margin-bottom: 36px;
      border-top: 1px solid $dirty_seagull;

      @media screen and (max-width: 1280px) {
        border-top: none;
      }
    }

    .temp-format-switcher {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      margin: 10px 20px;

      .temp-format-wrapper {
        display: flex;
        border-radius: 22px;
        background-color: $grey-hair;
        height: 28px;
        cursor: pointer;
        font-size: 12px;
        margin-bottom: 20px;
        .celsius,
        .fahrenheit {
          color: $inkwell !important;
          width: 44px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          border: none;
          border-radius: 22px;
          background-color: $grey-hair;
        }
        .weather-unit-f & .fahrenheit,  .weather-unit-c & .celsius {
          border-radius: 22px;
          border: 1px solid $dolphin;
          background-color: #fff;
          font-weight: 700;
        }
      }
    }
  }

  &--blueSkyAlt.m-weather#m-weather__news.v2 {
    background: $bluesky-gradient;
    margin-bottom: 40px;
    padding-bottom: 40px;
    .location-form__container {
      &.wrapper {

        .location-form-flex {
          min-height: unset;
          .heading-wrapper{
            margin: 5px 0;
          }
          &__form{
            margin-top: 0;
          }
          &__heading--heading {
            margin: 0;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: -0.6px;
          }
        }
      }
    }
    .m-weather-card {
      .weather-details__container {
        .weather-details{
          &__current{
            &--temp{
              .weather-details__current__format{
                vertical-align: top;
                font-size: 14px;
                line-height: 30px;
                color: $gotham;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, xs)) {
      margin-bottom: 36px;
      padding-bottom: 36px;
      .location-form__container {
        &.wrapper {
          .location-form-flex__heading--heading{
            font-size: 20px;
            font-weight: 800;
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, s) - 1px) {
      #m-weather-card {
        width: calc(100% - 60px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media screen and (min-width: map-get($breakpoints,s)) {
      background: none;
      margin-bottom: 64px;
      padding-bottom: 0;
      .location-form__container {
        &.wrapper {
          .location-form-flex__heading--heading{
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .m-weather-card {
        .weather-details__container {
          .weather-details{
            flex-direction: column;

            &__current{
              justify-content: space-between;
              &--temp{
                .weather-details__current__max-min{
                  display: none;
                }
              }
              &__weather {
                align-items: center;
                flex-direction: row;
                width: unset;
                &--icon {
                  .desc {
                    display: none;
                  }
                }
                &--desc{
                  display: block;
                }
              }
              &__max-min{
                display: block;
              }
            }
          }
        }
      }
      .error-message{
        flex-wrap: nowrap;
        flex-direction: row;
        &__text{
          flex-direction: column;
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, m)) {
      .location-form__container {
        &.wrapper {
          .location-form-flex__heading--heading{
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, l)),
      screen and (min-width: map-get($breakpoints, xl)),
      screen and (min-width: map-get($breakpoints, xxl))
    {
      .location-form__container {
        &.wrapper {
          .location-form-flex__heading--heading{
            font-size: 20px;
            font-weight: 800;
          }
        }
      }
    }

  }
}

.content--title{
  gap: 12px;
}

.m-weather__content{
  &.m-cb {
    background-image: none;
    margin-bottom: 0;
  }
  .m-cb {
    &__body{
      padding-top: 0;
    }
    &__title {
      margin: 24px 0;
    }
    &__carousel__btn {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .m-weather__banner {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media screen and (max-width: 900px) {
  .m-weather#m-weather__minimal {
    .location-form__container {
      width: 100%;
    }

    .weather-details__container {
      padding-top: 6px;
    }

    .weather-details__day--icon {
      top: 0;
    }
  }
}

@media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
  .m-weather.m-weather__channel {
    .weather-details-and-forecast {
      .weather-details {
        flex-direction: column;
        .weather-description {
          justify-content: center;
        }
        &--weather-basic {
          padding: 0px 56px;
        }

      }

      .forecast-switcher span {
        max-width: none;
        width: 50%;
      }
    }

    .video-wrapper {
      height: 213px;
      left: 0;
      margin: 0 auto;
      position: relative;
      right: 0;
      top: 30px;
      width: 100%;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      .video-player, .m-video-wafer__player--container {
        height: 213px;
        width: 360px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .m-weather.m-weather__channel {
    padding: 28px 10px;
    .video-wrapper {
      top: 35px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .weather-details{
      padding:20px 10px;
    }
  }
}


.m-weather.m-weather__channel_v2 {
  .video-wrapper {
    top: 316px;

    .m-video-wafer__player--container {
      .wafer-video-rendered {
        iframe {
          border-radius: 10px;
        }
      }
    }
  }
  .temp-format-switcher{
    display: flex;
  }
  .weather-details.v2{
    .temp-format-switcher{
      display: none;
    }
  }

  .days-wrapper,
  .hours-wrapper {
    .glide .glide__arrows {
      .glide__arrow--next {
        right: -15px;
      }
      .glide__arrow--prev {
        left: -15px;
      }
    }
  }

  .location-form__container.wrapper {
    .location-list {
      max-width: 100%;
    }
  }

  @media screen and (max-width: (map-get($breakpoints, l) - 1px)) {
    .m-weather__channel.v2 .temp-format-switcher{
      display: none;
    }
    .weather-details.v2{
      .temp-format-switcher{
        display: flex;
      }
    }

    .video-wrapper {
      top: 25px;
      height: 240px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
  .m-weather.m-weather__channel_v2 {
    .video-wrapper {
      top: 308px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .m-weather.m-weather__channel_v2 {
    .video-wrapper {
      top: 264px;
    }
  }
}

.weather-picker-open{
  height: 100%;
  overflow-y: hidden;

  .m-AolHeader {
    #header-form, .m-banner{
      display: none;
    }
  }
}
