@import '../../../base/assets/scss/_base/config';


@mixin title-font-large {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 800;
  letter-spacing: -0.6px;
  &--image{
    height: 18px;
    width: auto;
    margin-left: 4px;
  }
}

@mixin title-font-medium {
  font-size: 16px;
  line-height: 2;
  font-weight: 700;
  letter-spacing: -0.6px;
  &--image{
    height: 16px;
    width: auto;
    margin-left: 4px;
  }
}

@mixin business-politics-style-s {
  &__content--item {
    margin-bottom: 0;
  }
  &__cta--container {
    margin-top: 28px;
    a {
      margin-top: 8px;
    }
  }
}

@mixin business-politics-style-xl {
  &__content--item {
    margin-bottom: 0;
  }
  &__content--item:nth-child(n+2) {
    margin-top: 36px;
  }
  &__cta--container {
    margin-top: 20px;
  }
}

@mixin business-politics-style-xxl {
  &__content--item {
    margin-bottom: 0;
    margin-top: 0;
  }
  &__content--item:nth-child(n+2) {
    margin-top: 36px;
  }
  &__cta--container {
    a {
      margin-top: 0;
    }
  }
}

@mixin reset-vna-visibility{
  // Rest all to display and visible
  &--1, &--2, &--3, &--4, &--5, &--6{
    display: block;
    visibility: visible;
    .m-cb__link{
      display: block;
      visibility: visible;
    }
  }
}

@mixin vna-display-order-map($map){
  @each $key, $value in $map {
    &--#{$key}{
      order: $value;
    }
  }
}

@mixin set-vna-bottom-margins($margin){
  // Rest all to display and visible
  &--1, &--2, &--3, &--4, &--5, &--6{
    margin-bottom: $margin;
  }
}

.provider-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;

    &--container {
      position: relative;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__name {
    font-family: $font-family-sans-serif;
    margin: 0;
    padding: 0;
    color: $backwater;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
  }
}

.m-cb {
  text-align: left;
  font-size: 16px;
  font-family: $font-family-poppins;
  color: $batcave;
  margin-bottom: 24px;
  background: $bluesky-gradient;
  .m-cb{
    background:none;
  }
  .stream-container--homepage &{
    margin-bottom: 40px;
    padding-bottom: 40px;
    .m-cb{
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .stream-container--personal_finance &{

    .m-cb__title {
      font-size: 20px;
      font-weight: 800;
      line-height: 32px; 
      letter-spacing: -0.6px;
    }
  }


  &--divider {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.03) 100%);
    border: none;
  }

  &__title, .m-yss_h_menu_header__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.25;
    margin: 0;
    padding: 0 12px;
    letter-spacing: -0.75px;
    position: relative;

    &:before{
      content: '';
      height: 100%;
      background-color: $pineapple;
      width: 4px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 2px;
    }

    .stream-container--shopping &,
    .stream-container--homepage &{
      @include title-font-large;
    }

    + .m-cb__grid_image {
      margin-top: 24px;
    }
  }

  &__body {
    padding: 24px 0;
  }

  &__cta {
    display: inline-block;
    text-align: right;
    color: $picasso;
    font-size: 0.75em;
    line-height: 1em;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    border: 1px solid $picasso;
    border-radius: 50px;
    padding: 15px 24px;
    margin-bottom: 40px;
    background-color: #fff;
    white-space: nowrap;

    .stream-container--homepage &{
      margin-bottom: 0;
    }

    &:hover {
      color: white;
      background-color: $picasso;
    }

    &--container {
      display: flex;
      justify-content: center;
    }

  }

  &__link {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $picasso;
    }
  }

  &__content {
    &--right {
      .m-cb__heading {
        font-size: 0.875em;
        line-height: 1.428;
      }

      .m-cb__image {
        border-radius: 0.5em;
      }
    }

    &--space-around {
      justify-content: space-around;
    }

    &--lead{
      .m-cb__content--item{
        margin-bottom: 36px;
      }
      .m-cb__image{
        padding-top: 75%;
        border-radius: 20px;
        margin-bottom: 20px;
        box-shadow: $fluffy-pancake;
      }
      .m-cb__heading{
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
    }

    &--short-stack{
      .m-cb__content--item{
        margin-bottom: 36px;
        &:last-child{
          margin-bottom: 4px;
        }
      }
      .m-cb__image{
        padding-top: 100%;
        border-radius: 16px;
        margin-bottom: 16px;
        margin-bottom: 0;
      }
      .m-cb__heading{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }

  &__carousel {
    &--spacer{
      &:after {
        content: '';
        width: 20px;
        display: block;
        height: 20px;
        flex: 1 0 20px;
      }
    }
    &--container{
      position: relative;
    }
    &__btn{
      position: absolute;
      z-index: 10;
      height: 76px;
      width: 36px;
      border: 0;
      box-shadow: $fluffy-pancake;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10));
      background-color: rgba(255, 255, 255, 0.9);
      margin-top: calc(12% - 2.5rem);

      &[disabled]{
        display: none;
      }
      &:hover{
        background-color: $picasso;
        svg{
          fill: $white;
        }
      }
      &--inner{
        height: 20px;
        width: 20px;

        svg{
          fill: $picasso;
        }
      }
      &--prev{
        left: -1.25rem;
        border-top-right-radius: 36px;
        border-bottom-right-radius: 36px;
        padding-right: 10px;
      }
      &--next{
        padding-left: 10px;
        right: -1.25rem;
        border-top-left-radius: 36px;
        border-bottom-left-radius: 36px;
      }
    }
    @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }

      overflow-y: scroll;

      .grid-x {
        flex-wrap: nowrap;
      }

      & &--item {
        width: calc(66vw - 2.5rem);
      }

      & &--item-round {
        width: calc(25% - 1.25rem);
      }

      .m-cb__content--left,
      .m-cb__content--middle,
      .m-cb__content--right {
        width: auto;
      }
    }
  }

  &__carousel--xs-only {

    @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }

      overflow-y: scroll;

      .grid-x {
        flex-wrap: nowrap;
      }
    }
  }

  &__image {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 1em;
    overflow: hidden;
    margin-bottom: 20px;


    &--container {
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__carousel--round &__carousel--item-round {
    @media screen and (min-width: 90em) {
      width: 220px;
    }
  }

  &__carousel--item-round &__image {
    padding-top: 100%;
    box-shadow: 0px 16px 20px 0px #C7CDD266;
    border-radius: 100%;
  }

  &__grid_image {
    padding-bottom: 20px;
    padding-top: 20px;

    img {
      padding: 0;
      border-radius: 0;
      $margins: map-get($grid-container-padding, xxs);
      width: calc(100% + $margins);
    }
  }

  &__heading {
    margin: 0;
    font-size: 1em;
    font-weight: 700;
    line-height: 24px;

    &--center {
      text-align: center;
    }
  }

  &__summary {
    font-family: $font-family-base;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667;
    margin: 0;
  }

  &__subheading{
    color: $backwater;
    font-family: $font-family-poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin: 0 0 9px 0;
  }

  &__playBtn{
    color: $picasso;
    font-family: $font-family-poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    &--inner{
      border: 1px solid transparent;
      background-color: $solitude;
      padding: 5px 11px;
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .m-cb__link:hover &{
        border-color: $picasso;
      }
    }
    &--container{
      text-align: start;
      display: flex;
    }

  }

  &--1 {
    .m-cb {
      &__heading {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      &__summary {
        display: none;
      }

      &__image {
        border-radius: 16px;
        margin-bottom: 12px;
        padding-top: 75%;
        box-shadow: $fluffy-pancake;
      }
    }
  }

  &--2 {
    .m-cb {
      &__body {
        padding-bottom: 25px;
      }
      &__heading {
        font-size: 20px;
        line-height: 24px;
        max-width: 600px;
      }

      &__image {
        border-radius: 16px;
        padding-top: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &--3,
  &--3inline,
  &--4,
  &--4inline,
  &--5,
  &--6,
  &--6alt,
  &--9 {
    .m-cb {
      &__content {
        &--left {
          margin-bottom: 40px;

          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }

          .m-cb__image {
            border-radius: 20px;
            box-shadow: $fluffy-pancake;
            padding-top: 75%;
          }
        }

        &--right {
          .m-cb__link {
            //.m-cb__image--container {
            //  // This is needed as we are working with a subgrid, so can't be done via class name
            //  @include xy-cell-size($size: 4 of 12, $breakpoint: auto);
            //}
          }

          .m-cb__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
          }

          .m-cb__image {
            border-radius: 16px;
            padding-top: 100%;
          }
        }
      }
    }
  }

  &--6,
  &--6alt,
  &--9 {
    .m-cb {
      &__body{
        padding: 24px 0 28px 0;
      }
      &__content {

        &--left {
          .m-cb__heading{
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
          }
          .m-cb__image {
            box-shadow: $fluffy-pancake;
            border-radius: 20px;
            padding-top: 75%;
            margin-bottom: 20px;
          }
        }

        &--middle {
          margin-bottom: 40px;

          .m-cb__image {
            border-radius: 16px;
            padding-top: 100%;
            margin-bottom: 16px;
          }
        }

        &--right {
          div.grid-x {
            .m-cb__content--item:nth-child(n+3) {
              .m-cb__link {
                margin-bottom: 0;
              }
            }
          }

          .m-cb__link {
            margin-bottom: 36px;
          }
          .m-cb__link--1, .m-cb__link--2 {
            display: none;
          }


          .m-cb__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
          }

          .m-cb__image {
            border-radius: 16px;
            padding-top: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--6 {
    .m-cb {
      &__carousel__btn{
        margin-top: calc(12.5% - 2.5rem);
      }
      &__content {
        &--middle {
          .m-cb__heading{
            line-height: 24px;
          }
        }

      }
    }
  }

  &--6alt,
  &--9 {
    .m-cb {
      &__content {

        &--left {
          .m-cb__image {
            padding-top: 56.25%;
          }
        }

        &--middle {
          margin-bottom: 40px;

          .m-cb__link {
            margin-bottom: 28px;
          }

          .m-cb__image {
            border-radius: 16px;
            padding-top: 56.25%;
          }

          .m-cb__heading {
            font-size: 14px;
            line-height: 20px;
          }
        }

      }
    }
  }

  &--9 {
    .m-cb {

      &__carousel--xs-only{
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {

          & .m-cb__carousel--item {
            display: block;
            width: calc(36vw + 0.625rem);
          }

          .m-cb__content--left,
          .m-cb__content--middle,
          .m-cb__content--right {
            width: auto;
            .m-cb__heading{
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }
            .m-cb__image{
              padding-top: 56.25%;
              margin-bottom: 16px;
              border-radius: 12px;
            }
          }
        }
      }
      &__carousel__btn{
        margin-top: calc(21.5% - 2.5rem);
      }

      &__body{
        padding-bottom:0 ;
      }
      &__content {
        &--left{
          .m-cb__image{
            padding-top: 75%;
          }
        }
        &--middle {
          margin-bottom: 0;
          .m-cb__heading{
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }
        }
        &--right {
          margin-bottom: 0;
          &--left{
            @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
              margin: 0;
            }
          }
          .m-cb__link {
            padding: 0 0.625rem;
            margin-bottom: 24px;
          }

          .m-cb__image {
            border-radius: 12px;
            margin-left: 0;
            margin-right: 0;
            padding-top: 56.25%;
            margin-bottom: 16px;
          }

          .m-cb__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }
        }
      }
    }
  }

  &--6alt {
    .m-cb {

      &__carousel--xs-only {
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {

          & .m-cb__carousel--item {
            display: block;
            width: calc(36vw + 0.625rem);
          }

          .m-cb__content--left,
          .m-cb__content--middle,
          .m-cb__content--right {
            width: auto;

            .m-cb__heading {
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }

            .m-cb__image {
              padding-top: 75%;
              margin-bottom: 20px;
              border-radius: 16px;
            }
          }
        }
      }

      &__carousel__btn {
        margin-top: calc(21.5% - 2.5rem);
      }

      &__content {
        &--left {
          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }
        }

        &--middle {
          margin-bottom: 12px;

          .m-cb {
            &__heading {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }

            &__image {
              border-radius: 16px;
              padding-top: 100%;
            }
          }
        }

        &--right {
          .m-cb {
            &__heading {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }
      }

      &__cta {
        &--container {
          width: auto;
        }
      }
    }
  }

  &--6alt-inline {
    .m-cb {
      &__carousel--xs-only {
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {

          & .m-cb__carousel--item {
            display: block;
            width: calc(36vw + 0.625rem);
          }

          .m-cb__content--left,
          .m-cb__content--middle,
          .m-cb__content--right {
            width: auto;

            .m-cb__heading {
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }

            .m-cb__image {
              padding-top: 75%;
              margin-bottom: 20px;
              border-radius: 16px;
            }
          }
        }
      }

      &__carousel__btn {
        margin-top: calc(21.5% - 2.5rem);
      }

      &__body {
        padding: 24px 0 28px 0;
      }

      &__content {
        &--left {
          margin-bottom: 40px;

          .m-cb {
            &__heading {
              font-size: 20px;
              line-height: 28px;
              font-weight: 700;
            }

            &__image {
              padding-top: 75%;
              border-radius: 20px;
              box-shadow: $fluffy-pancake;
              margin-bottom: 20px;
            }
          }
        }

        &--middle {
          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }

            &__image {
              border-radius: 16px;
              padding-top: 100%;
              margin-bottom: 0px;
            }

            &__link {
              margin-bottom: 36px;

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }

        &--right {
          display: none;

          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }

            &__image {
              border-radius: 16px;
              padding-top: 100%;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__cta {
      &--container {
        width: auto;
      }
    }
  }

  &--g2,
  &--g3 {
    .m-cb {
      &__image {
        border-radius: 20px;
        padding-top: 100%;
      }

      &__heading {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &--3 {
    .m-cb {
      &__body {
        padding-bottom: 0;
      }

      &__content {
        &--left {
          .m-cb__heading {
            max-width: 600px;
          }
        }

        &--right {
          .m-cb__link {
            margin-bottom: 36px;

          }

          .m-cb__image {
            margin: 0;
          }
        }
      }

    }
  }

  &--3inline{
    .m-cb__body{
      padding-bottom: 28px;
    }
    .m-cb__content{
      &--left{
        margin-bottom: 40px;
        .m-cb__heading{
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        .m-cb__image{
          padding-top: 75%;
          border-radius: 20px;
        }
      }
      &--right{
        .m-cb__heading {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }

        .m-cb__image {
          border-radius: 16px;
          padding-top: 100%;
        }
      }
    }
  }

  &--4 {
    .m-cb {
      &__content--left {
        margin-bottom: 40px;
        .m-cb__heading {
          max-width: 600px;
        }
      }

      &__content--right {
        .m-cb__link {
          display: block;
          margin-bottom: 44px;
        }

        .m-cb__link:last-of-type {
          margin-bottom: 4px;
        }

        .m-cb__image {
          margin: 0;
        }
      }
    }
  }

  &--4inline {
    .m-cb {
      &__body{
        padding-bottom: 0;
      }
      &__mWeb {
        &__left {
          display: flex;
        }
        &__bottom {
          display: none;
        }
      }

      &__content{
        &--left {
          .m-cb__image {
            margin-bottom: 20px;
            padding-top: 75%;
          }
        }

        &--right {
          .m-cb__link {
            display: block;
            margin-bottom: 36px;
          }

          .m-cb__image {
            margin-bottom: 0;
            padding-top: 100%;
          }
        }

        &--right:last-child {
          .m-cb__link {
            margin-bottom: 28px;
          }
        }
      }
      &__carousel {
        &__btn {
          @media screen  and (max-width: (map-get($breakpoints, xs) - 1px)) {
            &--prev, &--next {
              display: none;
            }
          }
        }
      }
    }
  }

  &--5 {
    .m-cb {
      &__content--left {
        margin-bottom: 40px;

        .m-cb__heading {
          font-size: 20px;
          line-height: 24px;
        }
      }

      &__content--right {
        .m-cb__content--item {
          margin-bottom: 28px;
        }

        .m-cb__content--item:last-of-type {
          margin-bottom: 4px;
          .m-cb__link {
            .m-cb__image {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &--g3 {
    .m-cb {
      &__content {
        margin-bottom: 20px;
      }
    }
  }

  &--g2 {
    .m-cb {
      &__content {
        margin-bottom: 48px;
      }

      &__body {
        padding: 0px;
      }

      &__heading {
        font-size: 16px;
        line-height: 24px;
      }
      &__image {
        padding-top: 56.25%;
      }
    }
  }

  &__body__large {
    padding-bottom: 28px;
  }


  &--ss, &--vn {
    text-align: left;
    .m-cb__content {
      &--left, &--left_v2 {
        .m-cb {
          &__heading {
            font-size: 20px;
            line-height: 28px;
          }

          &__image {
            border-radius: 20px;
            box-shadow: $fluffy-pancake;
            padding-top: 75%;
          }
        }
        margin-bottom: 40px;
      }

      &--right, &--right_v2 {
        .m-cb {
          &__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 0;
          }

          &__link {
            div.grid-x {
              margin-bottom: 36px;
            }
          }

          &__link:last-of-type {
            div.grid-x {
              margin-bottom: 28px;
            }
          }

          &__content--list {
            .m-cb__item {
              margin-bottom: 36px;
              div.grid-x {
                margin-bottom: 0;
              }
            }
          }

          &__image {
            border-radius: 16px;
            padding-top: 100%;
            margin-bottom: 0;
          }
        }
        margin-bottom: 40px;
      }
    }
  }

  &--ss {
    &__horizontal_cta {
      display: none;
      a{
        display: none;
      }
    }
    &__vertical_cta {
      display: flex;
      a{
        display: block;
      }
    }
    .m-yss {
      &_h_menu {
        margin-bottom: 28px !important;
        position: relative;
        top: 4px;

        &--select_tabs {
          display: none;
        }
        &_with_glide {
          display: none !important;
        }
      }

      &__h {
        margin-bottom: 0px;

        .glide__track {
          width: calc(100% - 60px);
        }
        &-scores-container {
          display: none;
          a{
            display: none !important;
          }
          .glide__arrow--right,.glide__arrow--left{
            display: none!important;
          }
        }
      }
    }

    .m-cb {
      &__body {
        padding-bottom: 0;
      }
      &__content {
        &--left, &--left_v2 {
          .m-cb__image{
            box-shadow: $fluffy-pancake;
            @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
              padding-top: 56.25%;
            }
          }
        }
        &--right, &--right_v2 {
          margin-bottom: 20px;
          .m-cb__link:last-of-type{
            .m-cb__heading, .m-cb__image{
              margin-bottom: 0;
            }
          }
          .m-cb__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
          }
          .m-cb__link {
            margin-bottom: 36px;
            display: block;
            &:last-child {
              margin-bottom: 28px;
            }
          }
          .m-cb__heading, .m-cb__image{
            margin-bottom: 0px;
          }
        }

        &--right_v2 {
          .m-cb {

            &__link,
            &__link:last-of-type {
              div.grid-x {
                margin-bottom: 0px;
              }
            }
          }
        }

        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
          &--left_v2 {
            margin-bottom: 0px;
            .m-cb__link {
              .m-cb__image {
                margin-bottom: 16px;
                border-radius: 12px;
              }
            }
          }

          &--right_v2 {
            .m-cb__link:last-of-type {
              .m-cb__image {
                margin-bottom: 16px;
              }

              .m-cb__heading {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      &__extra_layer, &__last_layer {
        display: none;
        .m-cb__link {
          display: none;
        }
      }
      &__mid_layer {
        .m-cb__link {
          display: block;
        }
        .m-cb__cta--container{
          display: flex;
        }
      }

      &__carousel--xs-only {
        @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
          & .m-cb__carousel--item {
            display: block;
            width: calc(36vw + 0.625rem);
          }

          .m-cb__content--right_v2 {
            width: auto;

            .m-cb__heading {
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }

            .m-cb__image {
              padding-top: 56.25%;
              margin-bottom: 16px;
              border-radius: 16px;
            }
          }
        }
      }

      &__carousel__btn {
        margin-top: calc(15% - 4.5rem);
      }
    }
  }

  &--vn {
    &_pv{
      &_main, &_title {
        background-color: #000;
      }

      &_title {
        padding-bottom: 24px;
      }
      &_main {
        margin-top: -24px;
      }
    }

    .video-wrapper {
      right: 20px;
      top: 330px;
      width: 100%;
      height: 100%;

      .video-player,
      .m-video-wafer__player--container {
        position: relative;
        border-radius: 0px;
        overflow: hidden;
        height: 0;
        padding-top: 56.25%;

        .m-video-wafer__player{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }

    .m-cb {
      &__default {
        display: block;
      }
      &__xs_layer {
        display: none;
      }

      &__mWeb {
        &__right {
          display: flex;
        }
      }

      &__content{
        &--left{
          .m-cb__image{
            box-shadow: $fluffy-pancake;
          }
        }
        &--right{

          margin-bottom: 0
        }
      }
      &_DesktopContentTitle{
        display: none;
      }
      &_MobileContentTitle {
        display: flex;
        margin-bottom: 24px;
        padding-top: 24px;
      }
    }
  }

  &__scorecard_wrapper {
    height: 100%;

    .m-cb__body {
      height: 86%;
    }
  }

  &--games{
    .grid-x {
      .m-cb.cell.auto {
        display: none;
      }
    }
    .m-cb__body{
      padding-bottom: 16px
    }
    .m-cb__carousel {
      &__btn{
        margin-top: calc(21% - 2.5rem + 32px);
      }
    }
    .m-cb__content{
      &--wrap{
        align-items: flex-start;
      }
      .m-cb__image{
        padding-top: 56.25%;
        border-radius: 16px;
        margin-bottom: 16px;
      }

      .m-cb__heading{
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin: 0 0 12px 0;
      }
      .m-cb__subheading{
        color: $batcave;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        position: absolute;
      }
      .m-cb__summary{
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .m-cb__playBtn{
        line-height: 1.1;
        &--inner{
          padding: 9px 15px;
        }
      }
      &--left{
        margin-bottom: 24px;
        .m-cb__image {
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
        }
      }
      &--right{
        .m-cb__carousel--slides{
          margin-top: 32px;
        }
        .m-cb__heading {
          font-size: 16px;
          line-height: 24px;
        }
        .m-cb__summary{
          padding-right: 1.25rem;
        }
      }

      .m-cb__cta--conatainer {
        display: flex;
        width: 100%;
        justify-content: center;

        a {
          margin-top: 16px;
          margin-bottom: 40px;
        }
      }

      .m-cb__trivia-title--wrapper {
        display: flex;
        align-items: flex-start;

        .m-cb__trivia-title {
          margin-left: 30px;
          margin-bottom: 24px;
        }

        .m-cb__new-badge {
          padding: 3px 6px;
          border-radius: 10px;
          background-color: $bonsai;
          color: $white;
          line-height: 1;
          font-size: 12px;
          font-weight: 700;
          margin-top: 6px;
        }
      }
    }

    .m-cb__cta--container.trivia-games-cta {
      display: none;
    }
  }

  &--trivia-games-xxs {
    .m-cb__title {
      text-align: start;
      margin-bottom: 20px;
    }
  }

  &--food {
    .m-cb {
      &__body {
        padding-bottom: 0;
      }

      &__carousel {
        &__btn {
          margin-top: calc(21.5% - 2.5rem);
        }
      }
      .m-cb__link{
        margin-bottom: 28px;
        .m-cb__heading{
          margin-bottom: 0;
        }
      }

      &__content {
        &--left {
          .m-cb {
            &__heading {
              margin-bottom: 40px;
              font-size: 20px;
              line-height: 28px;
              font-weight: 700;
            }

            &__image {
              box-shadow: $fluffy-pancake;
              border-radius: 20px;
              padding-top: 75%;
              margin-bottom: 16px;
            }
          }
        }

        &--middle {
          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
              margin-bottom: 24px;
            }
            &__link {
              margin-bottom: 24px;
            }

            &__image {
              border-radius: 16px;
              padding-top: 56.25%;
              margin-bottom: 16px;
              &--container {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &--bn{
    .m-cb__carousel__btn{
      display: none;
    }
    .finance-market{
      display: none;
      .tab-wrap-link, #markets-autocomplete, .search-wrapper, .market-link .link{
        display: none;
      }
      .tabs-option{
        .tab{
          a{
            display: none;
          }
        }
      }
      @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, s) - 1px) {
        &.cell{
          #yfm-markets{
            border-radius: 20px;
          }
          margin: 0 ;
          background: $bluesky-gradient;
          @include xy-cell-size($size: 7 of 12, $breakpoint: xs, $gutter-type: padding);
          margin-bottom: 36px;
        }
      }
    }
    .business{
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
      @media screen and (max-width: map-get($breakpoints, xs) - 1px) {
        background: $bluesky-gradient;
        margin: 0 -0.625rem 40px;
        width: calc(100% + 1.25rem);
      }
      @media screen and (max-width: map-get($breakpoints, s) - 1px) {
        background: $bluesky-gradient;
      }
      #yfm-markets{
        margin-bottom: 0!important;
      }
      margin-bottom: 40px;
      .m-cb__content--right{
        padding-left: 20px;
        padding-right: 20px;
        .m-cb__content--item:nth-child(n+3){
          .m-cb__link {
            margin-bottom: 28px;
          }
        }
        .m-cb{
          &__link {
            margin-bottom: 36px;
          }

          &__heading {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
          }
          &__image {
            position: relative;
            height: 0;
            width: 100%;
            padding-top: 100%;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 0;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &__cta {
            &--container {
              flex-direction: row-reverse;
              margin-bottom: 40px;
            }
            display: inline-flex;
            padding: 15px 24px;
            flex-direction: column-reverse;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .politics{
      .finance-market{
        display: none;
      }
      .m-cb__content{
        &--left{
          .m-cb{
            &__heading {
              margin-bottom: 40px;
              font-size: 20px;
              line-height: 28px;
              font-weight: 700;
            }

            &__image {
              position: relative;
              height: 0;
              width: 100%;
              padding-top: 75%;
              border-radius: 20px;
              overflow: hidden;
              margin-bottom: 20px;
              box-shadow: $fluffy-pancake;

              &--container {
                width: 100%;
                display: block;
              }

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        &--right{
          .m-cb__content--item:nth-child(n+3){
            .m-cb__link {
              margin-bottom: 28px;
            }
          }
          .m-cb{
            &__link {
              margin-bottom: 36px;
            }

            &__heading {
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }

            &__image {
              position: relative;
              height: 0;
              padding-top: 100%;
              border-radius: 16px;
              overflow: hidden;
              margin-bottom: 0;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &__cta {
              &--container{
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .m-cb{
      &__body {
        padding-top: 0;
      }
      &__title{
        margin-bottom: 24px;
      }
      &_DesktopTitle{
        display: none;
      }
      &_MobileTitle{
        display: block;
      }
      &__finance-banner{
        display: block;
      }
    }

  }

  &--vna {
    .m-cb {
      &__title {
        margin-bottom: 24px;
      }
      &__content {
        &--right{

          .m-cb__content {
            &--list {
              .m-cb__image{
                border-radius: 16px;
              }
              @media screen and (max-width: (map-get($breakpoints, xs) - 1px)){
                .m-cb__item:nth-child(1) {
                  .m-cb__image{
                    padding-top: 75%;
                    border-radius: 20px;
                    margin-bottom: 20px;
                    margin-top: 0;
                    box-shadow: $fluffy-pancake;
                  }

                  .m-cb__heading{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    &--container{
                      order: 2;
                    }
                  }
                }
              }
              .m-cb__item{
                display: block;
                &.m-cb__item {
                  @include vna-display-order-map((
                    1: 1,
                    2: 6,
                    3: 2,
                    4: 5,
                    5: 3,
                    6: 4
                  ));
                  @include reset-vna-visibility;
                  // set never displayed
                  &--2, &--4{
                    display: none;
                    .m-cb__link{
                      display: none;
                    }
                  }

                  &--6 {
                    margin-bottom: 28px;
                  }

                }
              }
            }
          }
        }
        &--left{
          background: $bluesky-gradient;
          padding-bottom: 24px;
          margin-bottom: 40px;
          .video-wrapper {
            height: auto;
          }
          .m-video-wafer{
            margin-bottom: 0!important;
            &__player--container{
              padding-top: 75%;
              border-radius: 16px;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &--4inline-rounded {
    .m-cb {
      &__body {
        padding: 24px 0px 0px;
      }
      &__content--item {
        box-shadow: 0px 4px 8px 0px #0000001A, 0px 0px 1px 0px #0000001A;
        border-radius: 24px;
        background-color: $white;
        padding: 40px 16px 32px;
        margin-bottom: 28px;

        .flex-container {
          align-items: center;
          flex-grow: 1;
        }
      }
      &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__image {
        width: 52px;
        min-width: 52px;
        padding-top: 52px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 28px;
        border-radius: 0px;
      }

      &__heading {
        text-align: center;
        font-weight: 700;
        font-family: $font-family-poppins;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        color: $batcave;
      }

      &__summary {
        text-align: center;
        font-weight: 400;
        font-family: $font-family-sans-serif;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 28px;
        color: $batcave;
      }
      &__playBtn--inner {
        padding: 10px 16px;
      }
      &__playBtn--container {
        justify-content: center;
      }

      &__carousel {
        @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
          scrollbar-width: none; // Firefox Hide scrollbar
          -ms-overflow-style: none; // IE Hide scrollbar

          &::-webkit-scrollbar {
            // Webkit Hide scrollbar
            display: none;
          }

          overflow-y: scroll;

          .grid-x {
            flex-wrap: nowrap;
          }
        }

        &__btn {
          margin-top: calc(150px - 2.5rem)
        }
      }

      &--content-section {
        margin-right: 0px;
      }
    }
  }

  &--mc {
    .m-cb {
      &__content {
        &--lead {
          .m-cb__image {
            padding-top: 100%;
            border-radius: 16px;
            margin-bottom: 0;
          }

          .m-cb__heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
          }
        }

        @media screen and (min-width: map-get($breakpoints, xxs)) and (max-width: (map-get($breakpoints, xs) - 1px)) {
          &--short-stack .m-cb__content--item--2 {
            margin-bottom: 4px;
          }
        }
      }
      &__finance-markets {
        .finance-market {
          display: none;
          .tab-wrap-link, #markets-autocomplete, .search-wrapper, .market-link .link {
            display: none;
          }
          .tabs-option {
            .tab {
              a {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &--7 {
    .m-cb__content--middle {
      .m-cb__content--item--3 {
        margin-bottom: 4px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .m-cb {
    .stream-container--homepage &{
      margin-bottom: 36px;
      padding-bottom: 36px;
    }
    &__body {
      padding-top: 20px;
    }
    &__title, .m-yss_h_menu_header__title {
      .stream-container--shopping &,
      .stream-container--homepage &{
        @include title-font-large;
      }
    }

    &__content{
      &--lead,&--short-stack{
        .m-cb__content--item {
          margin-bottom: 4px;
        }
        .m-cb__image{
          padding-top: 56.25%;
          border-radius: 12px;
          margin-bottom: 16px;
          box-shadow: none;
        }
        .m-cb__heading{
          font-size: 13px;
          line-height: 20px;
        }

      }

      &--short-stack{
        .m-cb__link{
          flex-direction: column-reverse;
        }
      }
    }

    &--divider {
      border-bottom: 1px solid $pebble;
    }

    &__grid_image {
      img {
        width: 100%;
        border-radius: 12px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__cta {
      padding: 11px 20px;
      &--container {
        justify-content: flex-end;
        margin-bottom: 0;
      }
    }

    &--1 {
      .m-cb {
        &__heading {
          font-size: 16px;
          line-height: 24px;
        }

        &__image {
          border-radius: 12px;
          padding-top: 56.25%;
          box-shadow: none;
        }
      }
    }

    &--2 {
      .m-cb {
        &__image {
          border-radius: 12px;
          padding-top: 56.25%;
        }
      }
    }

    &--3,
    &--4 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 14px;
              line-height: 20px;
            }

            .m-cb__image {
              border-radius: 12px;
              padding-top: 56.25%;
              box-shadow: none;
            }
          }

          &--right {
            .m-cb__heading {
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
            }

            .m-cb__image {
              border-radius: 8px;
            }
          }
        }
      }
    }

    &--3inline{
      .m-cb__body{
        padding-bottom: 28px;
      }
      .m-cb__playBtn{
        font-size: 12px;
        &--inner{
          padding: 10px 16px;
        }
        &--container{
          justify-content: flex-start;
        }
      }
      .m-cb__content{
        &--left, &--right{
          margin-bottom: 0;
          .m-cb__link{
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .m-cb__heading{
            flex-grow: 1;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 12px;
          }
          .m-cb__image{
            padding-top: 56.25%;
            border-radius: 12px;
            box-shadow: none;
          }
        }
      }
    }

    &--4 {
      .m-cb {
        &__content {
          &--left {
            margin-bottom: 0;
          }
        }
      }
    }

    &--5 {
      .m-cb {
        &__body {
          padding-bottom: 0;
        }
        &__content {
          &--left {
            margin-bottom: 0;

            .m-cb__image {
              border-radius: 12px;
              padding-top: 100%;
              box-shadow: none;
            }
          }

          &--right {

            .m-cb__content--item:last-of-type {
              margin-bottom: 28px;
              .m-cb__link {
                .m-cb__image {
                  margin-bottom: 20px;
                }
              }
            }
            .m-cb__heading {
              font-weight: 600;
            }

            .m-cb__image {
              border-radius: 8px;
              padding-top: 56.25%;
            }
          }
        }
      }
    }

    &--6,
    &--6alt,
    &--9 {
      .m-cb__link {
        margin-bottom: 0;
      }
      .m-cb {
        &__body{
          padding-top: 20px;
        }

        &__content {

          &--left, &--middle, &--right{
            margin-bottom: 0;
            .m-cb__link {
              margin-bottom: 0;
            }
            .m-cb__image{
              box-shadow: none;
            }
          }
          &--right {
            .m-cb__link {
              &--1 , &--2{
                display: none;
              }
            }
          }
        }
      }
    }

    &--6{
      .m-cb {
        &__content {

          &--left,&--middle, &--right{
            .m-cb__heading{
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }
            .m-cb__image{
              padding-top: 56.25%;
              margin-bottom: 16px;
              border-radius: 12px;
            }
          }
          &--right {
            .m-cb__link.grid-x {
              flex-wrap: wrap;
              flex-direction: column-reverse;
            }
          }
        }

        &__cta {
          &--container {
            margin-top: 4px;
          }
        }
      }
    }

    &--6alt {
      .m-cb {
        &__carousel{
          &__btn{
            margin-top: calc(16% - 2.5rem);
          }
        }
        &__content {
          &--right {
            .m-cb__link.grid-x {
              flex-wrap: wrap;
              flex-direction: column-reverse;
            }
          }
        }
      }
    }

    &--9 {
      .m-cb {
        &__carousel{
          &__btn{
            margin-top: calc(16% - 4.5rem);
          }
        }
        &__body{
          margin-bottom: 28px;
        }
        &__content {

          &--right {
            .m-cb__link--4{
              margin-bottom: 28px;
            }
          }
        }
      }
    }

    &--6alt-inline {
      .m-cb {
        &__body {
          padding-top: 20px;
        }

        &__carousel {
          &__btn {
            margin-top: calc(16% - 2.5rem);
          }
        }

        &__content {

          &--left,
          &--middle,
          &--right {
            margin-bottom: 0;

            .m-cb {
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }

              &__image {
                padding-top: 56.25%;
                margin-bottom: 16px;
                box-shadow: none;
              }

              &__link {
                margin-bottom: 0;
              }
            }
          }

          &--right {
            display: flex;
          }
        }

        &__cta {
          &--container {
            margin-top: 4px;
          }
        }
      }
    }

    &--g3 {
      .m-cb {
        &__content {
          margin-bottom: 62px;
        }
      }
    }

    &--g2,
    &--g3 {
      .m-cb {

        &__content.img-alt {
          .m-cb__image {
            padding-top: 75%;
          }
        }

        &__image {
          border-radius: 8px;
          padding-top: 75%;
        }

        &__heading {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &--g2 {
      .m-cb {
        &__image {
          padding-top: 56.25%;
        }
        &__heading {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &--vn {
      &_pv{
        &_main, &_title {
          background-color: transparent;
          color: #000;
        }

        &_title {
          padding-bottom: 0px;
        }
        &_main {
          margin-top: 0;
        }
      }

      .video-wrapper {
        .video-player,
        .m-video-wafer__player--container {
          border-radius: 16px;
        }
      }

      .m-cb {
        &__default {
          display: none;
        }
        &__xs_layer {
          display: block;
        }

        &__mWeb {
          &__right {
            display: none;
          }
        }

        &_DesktopContentTitle{
          display: flex;
        }
        &_MobileContentTitle {
          display: none;
        }
      }

      .m-cb__content {
        &--left {
          .m-cb {
            &__heading {
              font-size: 14px;
              line-height: 20px;
            }

            &__image {
              border-radius: 8px;
            }
          }
        }

        &--right {

          a.m-cb__link {
            div.grid-x {
              margin-bottom: 36px;
            }
          }
          a.m-cb__link:nth-child(3) {
            div.grid-x {
              margin-bottom: 28px;
            }
          }
          a.m-cb__link:nth-child(n+4) {
            display: none;
          }
          .m-cb {
            &__heading {
              font-size: 13px;
              line-height: 20px;
              margin-bottom: 0;
            }
          }
        }
      }

    }

    &--ss {

      .m-yss {
        &__vs {
          display: none;
          .m-yss__vs--link{
            display: none;
          }
        }
        &_h_menu {
          &--select_tabs {
            display: flex;
          }
        }
        &__h {
          &-scores-container {
            display: flex;
            a{
              display: table-cell !important;
            }
            .glide__arrow--right,.glide__arrow--left{
              display: block !important;
            }
          }
        }
      }

      .m-cb__content {
        &--left, &--left_v2 {
          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
            }

            &__image {
              border-radius: 16px;
              box-shadow: none;
            }
          }
        }

        &--right, &--right_v2 {
          margin-bottom: 0px;
          .m-cb__link {
            margin-bottom: 0;
            div.grid-x {
              margin-bottom: 36px;
            }
          }
          .m-cb {
            &__heading {
              font-size: 13px;
              line-height: 20px;
              font-weight: 600;
            }

            &__image--container {
              max-width: 80px;
              .m-cb__image {
                border-radius: 8px;
              }
            }
          }
        }

        &--right_v2 {
          .m-cb {
            &__image--container {
              max-width: 100%;
              .m-cb__image {
                border-radius: 12px;
              }
            }
            &__heading {
              font-weight: 700;
            }
            &__link {
              margin-bottom: 0;
            }

            &__link, &__link:last-of-type {
              div.grid-x {
                margin-bottom: 0px;
              }
            }
          }
        }

        &--left_v2 {
          .m-cb {
            &__heading {
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
      }
      .m-cb--ss__vertical_cta {
        padding-top: 28px;
      }
    }

    &--games{
      .m-cb__body{
        padding-bottom: 24px
      }
      .m-cb__image{
        box-shadow: $fluffy-pancake;
        border-radius: 12px;
      }
      .m-cb__carousel{
        &__btn{
          margin-top: calc(11vw - 2.5rem)
        }
      }
      .m-cb__content{
        &--wrap{
          flex-direction: column;
          .m-cb__summary {
            flex: 1 1 100%;
          }
        }
        .m-cb__heading {
          font-size: 13px;
        }
        &--left{
          @include xy-cell-size($size: 5 of 12, $breakpoint: xs);
          .m-cb__image{
            padding-top: 56.25%;
            border-radius: 12px;
          }
        }
        &--right{
          .m-cb__carousel--slides{
            margin-top: 0px;
          }
          @include xy-cell-size($size: 7 of 12, $breakpoint: xs);
          .m-cb__item{
            @include xy-cell-size($size: 5 of 7, $breakpoint: xs);
            .m-cb__image{
              padding-top: 56.25%;
              border-radius: 12px;
            }
          }
        }

        &--trivia-games {
          @include xy-cell-size($size: 10 of 12, $breakpoint:xs);
          @include xy-cell-offset($n: 1 of 12, $breakpoint: xs)
        }

        .m-cb__cta--conatainer {
          margin-right: 30px;
          justify-content: end;

          a {
            margin-top: 4px;
            margin-bottom: 25px;
          }
        }
      }

    }

    &--4inline {
      .m-cb {
        &__mWeb {
          &__left {
            display: none;
          }
          &__bottom {
            display: flex;
          }
        }

        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }

              &__image {
                border-radius: 12px;
                padding-top: 56.25%;
                box-shadow: none;
              }
            }
          }
          &--right {
            .m-cb {
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }

              &__image {
                border-radius: 12px;
                padding-top: 56.25%;
                margin-bottom: 20px;
              }

              &__content:last-of-type {
                @media screen  and (max-width: (map-get($breakpoints, s) - 1px)) {
                  .m-cb__cta {
                    display: none;
                    &--container {
                      display: none;
                    }
                  }
                }
              }

            }
          }
        }

        &__carousel {
          &__btn {
            &--prev, &--next {
              margin-top: calc(11vw - 2.5rem)
            }
          }
        }
      }
    }

    &--food {
      .m-cb__carousel__btn{
        margin-top: calc(11vw - 2.5rem)
      }
      .m-cb {
        &__content {
          &--left {
            .m-cb {
              &__heading {
                margin-bottom: 0;
                font-size: 13px;
                line-height: 20px;
              }
              &__image{
                padding-top: 56.25%;
                box-shadow: none;
                border-radius: 16px;
              }
            }
          }

          &--middle {
            .m-cb {
              &__image {
                padding-top: 56.25%;
                border-radius: 16px;
                box-shadow: none;
              }
              &__link{
                display: block;
                margin-bottom: 24px;
              }

              &__heading {
                margin-bottom: 0;
                font-size: 13px;
                line-height: 20px;
              }
              &__cta{
                margin-top: 4px;
              }
            }
          }
        }
      }
    }

    &--bn{
      margin-top: 0 !important;
      .m-cb__carousel__btn {
        margin-top: calc(12% - 2.5rem);
        &:not([disabled]){
          display: block;
        }
      }

      .business{
        .m-cb__content--right{
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 28px;
          .m-cb__content--item:nth-child(n+3){
            .m-cb__link {
              margin-bottom: 0;
            }
          }
          .m-cb{
            &__link {
              flex-direction: column-reverse;
              margin-bottom: 0;
            }
            &__heading {
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }

            @include business-politics-style-s;
            &__image {
              padding-top: 56.25%;
              margin-bottom: 16px;
              border-radius: 12px;
            }
          }
        }
        .m-cb__cta {
          padding: 11px 20px;
          margin-right: 1.25rem;
          &--container {
            margin-top: 20px;
          }
        }
      }

      .politics{
        &--left{
          padding-right: 0;
        }
        &--right{
          padding-left: 0;
        }
        .m-cb{
          &__title{
            margin-bottom: 20px;
          }
        }
        .m-cb__content{
          &--left{
            .m-cb{
              &__heading {
                margin-bottom: 0;
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }

              &__image--container {
                display: block;
              }
              &__image {
                margin-bottom: 16px;
                border-radius: 12px;
                padding-top: 56.25%;
                box-shadow: none;
              }
            }
          }
          &--right{
            .m-cb__content--item:nth-child(n+3){
              .m-cb__link {
                margin-bottom: 0px;
              }
            }
            .m-cb{
              @include business-politics-style-s ;

              &__link {
                margin-bottom: 0;
                flex-direction: column-reverse;
              }

              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }
              &__image--container {
                display: block;
              }
              &__image {
                border-radius: 12px;
                padding-top: 56.25%;
                margin-bottom: 16px;
              }
              &__cta {
                padding: 11px 20px;
                &--container {
                  margin-top: 20px;
                }
              }
            }
          }
        }

      }
      .m-cb__cta--container-politics{
        margin-top: 28px;
      }
    }
    &--vna {
      .m-cb {
        &__title {
          margin-bottom: 20px;
        }
        &__content {
          &--left{
            background: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          &--right{
            .m-cb{
              &__image{
                &--cell{
                  display: none;
                }
              }
              &__content {
                &--list {
                  .m-cb__item{
                    margin-bottom: 24px;
                    &.m-cb__item {
                      @include set-vna-bottom-margins(24px);
                      @include reset-vna-visibility;
                      // set never displayed
                      &--2, &--4, &--6{
                        display: none;
                        .m-cb__link{
                          display: none;
                        }
                      }
                      &--5{
                        margin-bottom: 0;
                      }
                    }
                  }
                  .m-cb__heading{
                    margin-bottom: 0;
                    font-size: 13px;
                  }
                }
              }
            }

          }
        }
        &__cta{
          &--container{
            justify-content: flex-start;
            margin-top: 28px;
          }
        }

      }
    }

    &--mc {
      .m-cb {
        &__content {
          &--lead {
            .m-cb__image {
              padding-top: 56.25%;
              border-radius: 12px;
              margin-bottom: 16px;
            }

            .m-cb__heading {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-cb {
    background: none;

    &__carousel {
      &--spacer{
        &:after {
          display: none;
        }
      }
    }
    .stream-container--homepage &{
      margin-bottom: 32px;
      padding-bottom: 32px;
    }

    .stream-container--hub.finshop &{

      
      .m-cb {
        &__content {
          @include xy-cell-size($size: 5.5 of 11, $breakpoint: s);
        }

        &__content--right {
          margin-left: 10px;
          @include xy-cell-size($size: 5.5 of 11, $breakpoint: s);
        }
      }
    }

    .stream-container--personal_finance &{

      .m-cb__title {
        font-size: 16px;
        line-height: 2;
        font-weight: 700;
        letter-spacing: -0.6px;
      }
    }
    &__body {
      padding-top: 28px;
    }
    &__title, .m-yss_h_menu_header__title {
      .stream-container--shopping &,
      .stream-container--homepage &{
        @include title-font-medium;
      }
    }
    &__grid_image {
      img {
        border-radius: 16px;
      }
    }

    &__content{
      &--lead{
        .m-cb__content--item{
          margin-bottom: 4px;
        }
        .m-cb__image{
          padding-top: 75%;
          border-radius: 20px;
          margin-bottom: 16px;
        }
        .m-cb__heading{
          font-size: 16px;
          line-height: 24px;
        }
      }

      &--short-stack{
        .m-cb__content--item{
          margin-bottom: 32px;
        }
        .m-cb__link{
          flex-direction: unset;
        }
        .m-cb__image{
          padding-top: 100%;
          border-radius: 8px;
          margin-bottom: 0;
          &--container{
            width: 72px;
          }
        }
        .m-cb__heading{
          font-size: 13px;
          line-height: 20px;
          font-weight: 600;
        }
      }
    }

    &--ec, &--tc {
      .m-cb--elections, .m-cb--trueCrime{
        .m-cb__content{
          &--lead{
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);
          }
          &--short-stack{
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);
          }
        }
      }
      .m-cb--content{
        .m-cb__content{
          &--lead{
            @include xy-cell-size($size: 2 of 5, $breakpoint: auto);
            .m-cb__image{
              padding-top: 100%;
              border-radius: 16px;
            }
            .m-cb__heading{
              font-size: 13px;
              font-weight: 700;
            }
          }
          &--short-stack{
            @include xy-cell-size($size: 3 of 5, $breakpoint: auto);
            .m-cb__image{
              &--container{
                display: none;
              }
            }
          }
        }
      }
    }
    &--1 {
      .m-cb {
        &__content {
          @include xy-cell-size($size: 5 of 11, $breakpoint: auto);

          &--main {
            @include xy-cell-size($size: 6 of 11, $breakpoint: auto);
          }
        }

        &__heading {
          font-size: 20px;
          line-height: 28px;
        }

        &__summary {
          display: block;
          font-size: 12px;
          line-height: 20px;
        }

        &__image {
          border-radius: 12px;
        }
      }
    }

    &--2 {
      .m-cb {
        &__image {
          border-radius: 12px;
        }
      }
    }

    &--3 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }

            .m-cb__image {
              border-radius: 12px;
            }
          }

          &--right {
            .m-cb__heading {
              font-size: 14px;
              line-height: 20px;
              @include xy-cell-size($size: 3 of 5);
            }

            .m-cb__image {
              border-radius: 8px;
              padding-top: 75%;

              &--container {
                @include xy-cell-size($size: 2 of 5);
              }
            }
          }
        }
      }
    }

    &--3inline{
      .m-cb__body{
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .m-cb__playBtn{
        font-size: 11px;
        &--inner{
          padding: 7px 11px;
          height: 36px;
          width: 73px;
        }
        &--container{
          justify-content: flex-end;
        }
      }
      .m-cb__heading{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-right: 10px;
      }

      .m-cb__content--wrap {
        flex-basis: auto;
        align-items: flex-start;
      }
      .m-cb__content{
        &--left{
          .m-cb {
            &__image{
              border-radius: 20px;
            }
            &__heading {
              margin: 0 10px 0 0;
              font-size: 16px;
              line-height: 20px;
            }
            &__playBtn {
              font-size: 12px;
            }
          }
        }
        &--right {
          .m-cb {
            &__heading {
              font-size: 13px;
              line-height: 20px;
            }
            &__image {
              border-radius: 12px;
            }
            &__playBtn{
              &--container{
                width: 100%;
              }
            }
          }

          > .grid-x{
            margin-bottom: 28px;
            &.m-cb__cta--container{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &--4 {
      .m-cb {
        &__body {
          padding-bottom: 24px;
        }
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }

            .m-cb__image {
              border-radius: 16px;
            }
          }

          &--right {
            .m-cb__heading {
              font-size: 12px;
              line-height: 16px;
            }

            .m-cb__image {
              max-width: 116px;
              border-radius: 16px;
              padding-top: min(100%, 116px);
            }
          }
        }
      }
    }

    &--5 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 12px;
              padding-top: 100%;
            }
          }

          &--right {
            .m-cb__heading {
              font-weight: 700;
            }

            .m-cb__image {
              border-radius: 8px;
            }
          }
        }
      }
    }

    &--6,
    &--6alt,
    &--9 {

      .m-cb {

        &__content{
          &--middle{
            .m-cb__link {
              margin-bottom: 24px;

              .m-cb__heading {
                font-size: 13px;
                line-height: 20px;
              }
              .m-cb__image {
                border-radius: 12px;
              }
            }
          }
          &--right {
            .m-cb__link {
              margin-bottom: 32px;
            }
            .m-cb__heading {
              font-size: 13px;
              line-height: 20px;
            }

          }
        }

        .m-cb__link:last-of-type {
          margin-bottom: 28px;
        }


        &__body{
          padding-top: 28px;
          padding-bottom: 0;
        }
      }
    }

    &--6alt {
      .m-cb {
        &__content {

          &--left {
            .m-cb {
              &__heading {
                font-size: 16px;
                line-height: 24px;
              }

              &__image {
                padding-top: 75%;
              }
            }
          }

          &--middle {

            .m-cb {
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 700;
              }

              &__image {
                border-radius: 12px;
                padding-top: 56.25%;
              }
            }
          }

          &--right {
            .m-cb {
              &__heading {
                font-size: 13px;
                font-weight: 600;
                line-height: 20px;
              }
              &__image--cell {
                display: flex;
                justify-content: flex-end;
              }
              &__image--container {
                display: none;
              }
              &__image {
                border-radius: 8px;
                padding-top: 100%;
              }

              &__link.grid-x {
                flex-wrap: nowrap;
                flex-direction: row;
              }
            }

            div.grid-x {
              .m-cb__content--item:nth-child(n+3) {
                .m-cb__link {
                  margin-bottom: 28px;
                }
              }
            }
          }
        }
      }
    }

    &--9 {
      .m-cb {
        &__body{
          margin-bottom: 0;
        }
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 16px;
              line-height: 20px;
              font-weight: 700;
            }
          }
          &--middle {
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);

            .m-cb__link--3, .m-cb__link--4{
              display: none;
            }
          }

          &--right {
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);
            .m-cb__heading {
              font-weight: 600;
            }
            .m-cb__link {
              display: block;
              padding: 0;
              &--4 {
                margin-bottom: 28px;
              }
            }
            .m-cb__link--4, .m-cb__link--5, .m-cb__link--6{
              display: none;
            }
          }
        }
      }
    }

    &--6{
      .m-cb {
        &__content {
          &--left{
            .m-cb__heading{
              font-size: 16px;
              line-height: 20px;
              font-weight: 700;
            }
            .m-cb__image{
              padding-top: 75%;
              margin-bottom: 20px;
              border-radius: 20px;
            }
          }
          &--middle{
            .m-cb__heading{
              font-size: 13px;
              line-height: 20px;
              font-weight: 700;
            }
            .m-cb__image{
              padding-top: 100%;
            }
          }
          &--right {
            .m-cb__heading{
              font-weight: 600;
              font-size: 13px;
            }
            .m-cb__image{
              padding-top: 100%;
            }
            .m-cb__link{
              &.grid-x{
                flex-direction: row;
              }
            }
            .m-cb__image--container {
              display: none;
            }
            .m-cb__cta{
              &--container{
                margin-top: 28px;
              }
            }
          }
        }
      }
    }

    &--g3,
    &--g2 {
      .m-cb {
        &__heading {
          font-size: 14px;
          line-height: 20px;
        }

        &__content.img-alt {
          .m-cb__image {
            padding-top: 100%;
          }
        }

        &__image {
          border-radius: 12px;
          padding-top: 56.25%;
        }

      }
    }

    &--g2{
      .m-cb {
        &__heading {
          font-size: 16px;
          line-height: 24px;
        }
        &__content {
          @include xy-cell-size($size: 5 of 11, $breakpoint: s);

          &--right {
            @include xy-cell-offset($n: 1 of 11, $breakpoint: auto)
          }
        }
      }
    }

    &--vn {
      .video-player,
      .m-video-wafer__player--container {
        border-radius: 12px;
      }

      .m-cb {
        &__default {
          display: block;
        }
        &__xs_layer {
          display: none;
        }

        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 16px;
                line-height: 20px;
              }

              &__image {
                border-radius: 16px;
              }
            }
          }

          &--right {
            a.m-cb__link {
              div.grid-x {
                margin-bottom: 32px;
              }
            }
            a.m-cb__link:nth-child(n+4) {
              display: block;
            }
            margin-bottom: 0;
            .m-cb {
              &__link {
                &__heading {
                  font-size: 13px;
                  margin-bottom: 0;
                }
              }
            }

          }
        }

        &__cta--container {

          a.m-cb__cta {
            margin-top: 0;
          }
        }
      }
    }

    &--ss {
      &__horizontal_cta {
        display: flex;
        a{
          display: block;
        }
      }
      &__vertical_cta {
        display: none;
        a{
          display: none;
        }
      }
      .m-cb__content {
        &--left, &--left_v2 {
          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        &--right, &--right_v2 {
          .m-cb__link {
            div.grid-x {
              margin-bottom: 32px;
            }
            &:last-child{
              margin-bottom: 0;
            }
            .m-cb__heading {
              font-size: 13px;
              line-height: 20px;
            }
          }
          .m-cb {
            &__image--container {
              max-width: 100%;
            }
            &__image{
              padding-top: 56.25%;
            }
          }
          .hide-for-m-break {
            display: flex;
          }
        }

        &--left_v2 {
          .m-cb {
            &__image {
              padding-top: 75%;
              border-radius: 20px;
            }
          }
        }

        &--right_v2 {
          .m-cb {
            &__heading {
              font-weight: 600;
            }
            &__link, &__link:last-of-type {
              div.grid-x {
                margin-bottom: 28px;
              }
            }
          }
        }

      }
    }

    &--games{
      .grid-x {
        .m-cb.cell.auto {
          display: block;
          h2.m-cb__title {
            position: relative;
            span.m-cb__new-badge {
              position: absolute;
              top: 5px;
              padding: 3px 6px;
              border-radius: 10px;
              background-color: $bonsai;
              color: $white;
              line-height: 1;
              font-size: 12px;
              font-weight: 700;
              margin-left: 10px;
            }
          }
        }
      }
      .m-cb__image{
        box-shadow: none;
      }
      .m-cb__cta {
        margin: 0;
        padding: 11px 20px;
        &--container {
          width: 100%;
          justify-content: end;
          display: flex;

        }
      }
      .m-cb__content{
        .m-cb__subheading{
          position: static;
          margin-bottom: 12px;
          margin-top: -3px;
        }
        &--left.trivia-games {
          @include xy-cell-size($size: 3 of 7, $breakpoint:s);
        }
        &--left{
          @include xy-cell-size($size: 3 of 11, $breakpoint:s);
          .m-cb {
            &__image {
              padding-top: 100%;
              border-radius: 20px;
              box-shadow: none;
            }
            &__heading{
              font-size: 14px;
              line-height: 20px;
              font-weight: 700;
              margin-bottom: 8px;
            }
            &__summary{
              margin-right: 0;
              flex: 1 1;
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              margin-bottom: 16px;
            }
            &__playBtn {
              font-size: 11px;
              &--inner {
                height: 32px;
                width: 56px;
              }
            }
          }
        }
        &--right.trivia-games {
          @include xy-cell-size($size: 4 of 7, $breakpoint:s);

          .m-cb__item{
            @include xy-cell-size($size: 2 of 2, $breakpoint: s);
            .flex-child-shrink {
              height: 72px;
            }
            .m-cb__link {
              width: 250px;
            }
          }

          .m-cb__carousel--slides {
            flex-direction: column;
            .m-cb__item:nth-child(n+4) {
              display: none;
            }
          }
        }
        &--right{
          .m-cb__cta--container{
            margin-top: -8px;
          }
          @include xy-cell-size($size: 8 of 11, $breakpoint:s);
          .m-cb__item{
            @include xy-cell-size($size: 1 of 2, $breakpoint: s);
            .m-cb__heading{
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 0;
            }
            .m-cb__image{
              margin-bottom: 0;
              padding-top: 75%;
              border-radius: 8px;
              box-shadow: 0px 16px 20px 0px rgba(199, 205, 210, 0.52),
                0px 0px 16px 0px rgba(0, 0, 0, 0.05);

              &--container{
                width: 96px;
              }
              &--cell{
                max-width: 96px;
                margin-right: 16px;
              }
            }
            .m-cb__link{
              height: 100%;
              justify-content: flex-start;
              width: 95%;
            }
            margin-bottom: 36px;
            .flex-child-shrink{
              height: 100%;
              align-self: center;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
          .m-cb__playBtn {
            font-size: 10px;
            &--inner {
              height: 28px;
              width: 46px;
            }
          }
        }

        &--trivia-games {
          @include xy-cell-size($size: 5 of 11, $breakpoint:s);
          @include xy-cell-offset($n: 0 of 11, $breakpoint: s)
        }
      }
      .m-cb__body{
        padding-bottom: 0;
      }

    }

    &--4inline {
      .m-cb__body{
        padding-bottom: 0;
      }
      .m-cb__content {
        margin-bottom: 0;
        &--left {
          .m-cb {
            &__heading {
              font-size: 16px;
              line-height: 20px;
            }

            &__image {
              border-radius: 20px;
              padding-top: 56.25%;
              box-shadow: none;
            }
          }
        }

        &--right {
          .m-cb {
            &__heading {
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
            }
            &__image {
              padding-top: 75%;
            }
          }
        }
      }
      .m-cb__content:last-of-type {
        .m-cb__cta--container {
          a {
            margin-top: 0;
          }
        }
      }
    }

    &--food {
      .m-cb {
        &__content {
          &--left {
            @include xy-cell-size($size: 4  of 7, $breakpoint: s);
            .m-cb {
              &__image {
                border-radius: 20px;
                padding-top: 100%;
              }
              &__heading {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }

          &--middle {
            @include xy-cell-size($size: 3  of 7, $breakpoint: s);

            .m-cb__carousel--item:last-of-type {
              margin-bottom: 24px;
              .m-cb__link {
                margin-bottom: 0;
              }
            }

            .m-cb__link{
              display: flex;
              flex-direction: row-reverse;
              margin-bottom: 32px;

              .m-cb__heading{
                padding-right: 10px;
                margin-bottom: 0;
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
              }
              .m-cb__image{
                padding-top: 100%;
                margin-bottom: 0;
                border-radius: 8px;
                &--container {
                  flex: 0 0 72px;
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &--bn{
      .m-cb__body{
        padding-top: 20px;
      }
      .finance-market{
        display: none;
      }
      .business{
        margin-bottom: 0;
        padding-right: 0;
        padding-bottom: 0;
        .m-cb__content--right{
          div.grid-x:first-child {
            background: none;
          }
          .m-cb{
            &__link{
              flex-direction: row;
            }
            &__heading{
              font-weight: 600;
            }
            &__content--item {
              margin-bottom: 0;
            }
            &__content--item:nth-child(n+2) {
              margin-top: 32px;
            }
            &__image {
              padding-top: 100%;
              width: 72px;
              margin-bottom: 0;
              border-radius: 8px;
              &--cell{
                display: flex;
                justify-content: flex-end;
              }
              &--container {
                width: 72px;
                display: block;
              }
            }
          }
        }
        .m-cb__cta {
          padding: 11px 20px;
          margin-right: 0;
          &--container {
            margin-top: 28px;
            margin-bottom: 0;
            a {
              margin-top: 0px;
            }
          }
        }
      }

      .politics{
        .m-cb__content{
          &--left{
            .m-cb{
              &__heading {
                font-size: 14px;
                line-height: 20px;
              }

              &__image--container {
                display: block;
              }
              &__image {
                padding-top: 100%;
                border-radius: 20px;
              }
            }
          }
          &--right{
            margin-top: 0;
            .m-cb{
              &__link{
                flex-direction: row;
              }
              &__heading{
                font-weight: 600;
              }
              &__content--item:first-of-type {
                margin-top: 0
              }
              &__content--item {
                margin-bottom: 0;
              }
              &__content--item:nth-child(n+2) {
                margin-top: 32px;
              }
              &__cta {
                padding: 11px 20px;
                &--container {
                  margin-top: 20px;
                }
              }
              &__image--container {
                display: none;
              }

            }
          }
        }
      }

      .m-cb{
        > .m-cb__title{
          margin-bottom: 0;
        }
        &_DesktopTitle{
          display: block;
        }
        &_MobileTitle{
          display: none;
        }
        &__finance-banner{
          display: block;
        }
      }
    }
    &--vna {
      .m-cb {
        &__title {
          margin-bottom: 20px;
        }
        &__content {
          &--right{
            &.m-cb__item:last-child {
              margin-bottom: 0;
            }
            .m-cb{
              &__content--list{
                .m-cb__item{
                  .m-cb__heading{
                    margin-bottom: 0;
                  }

                  &.m-cb__item {
                    @include reset-vna-visibility;
                    @include vna-display-order-map((
                      1: 1,
                      2: 2,
                      3: 3,
                      4: 4,
                      5: 5,
                      6: 6
                    ));
                    // set visible conditions
                    &--2 ,&--4{
                      visibility: hidden;
                      .m-cb__link{
                        visibility: hidden;
                      }
                      .mpu--small &{
                        visibility: visible;
                        .m-cb__link{
                          visibility: visible;
                        }
                      }
                    }
                    // set never displayed
                    &--5, &--6{
                      display: none;
                      .m-cb__link{
                        display: none;
                      }
                    }

                    &--4, &--3{
                      margin-bottom: 0;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }

    &--4inline-rounded {
      .m-cb {
        &__heading {
          font-size: 18px;
          text-align: left;
        }

        &__summary {
          font-size: 14px;
          text-align: left;
        }

        &__playBtn--container {
          justify-content: flex-start;
        }

        &__image {
          width: 60px;
          min-width: 60px;
          padding-top: 60px;
          margin-bottom: 0px;
        }

        &__content--item {
          margin-bottom: 20px;
          padding: 40px 28px 32px;
          &:nth-child(3), &:nth-child(4) {
            margin-bottom: 28px;
          }

          &--2,
          &--3 {
            &.cell {
              margin-left: 10px;
              margin-right: 10px;
            }
          }

          &--1 {
            &.cell {
              margin-right: 10px;
            }
          }

          &--4 {
            &.cell {
              margin-left: 10px;
            }
          }
        }

        &__cta {
          margin: 0;
          padding: 11px 20px;

          &--container {
            width: 100%;
            justify-content: end;
          }
        }

        &--content-section {
          margin-right: 20px;
        }
      }
    }

    &--mc{
      .m-cb {
        &__cta--container{
          margin-top: 28px;
        }
        &__finance-markets {
          .finance-market {
            display: block;

            .tab-wrap-link, #markets-autocomplete, .search-wrapper, .market-link .link {
              display: block;
            }

            .tabs-option {
              .tab {
                a {
                  display: block;
                }
              }
            }
          }

        }
        &__content{
          &--lead{
            .m-cb__image{
              padding-top: 56.25%;
            }
            .m-cb__heading{
              font-size: 13px;
              font-weight: 700;
              margin-bottom: 24px;
              line-height: 20px;
            }
          }
          &--short-stack{
            .m-cb__content--item:nth-of-type(4){
              margin-bottom: 0;
            }
            .m-cb__image{
              &--container{
                display: none;
              }
            }
          }
        }
        &__body {
          padding-top: 28px;
          padding-bottom: 0;
        }
      }
    }

    &--7{
      .m-cb {
        &__content--lead{
          .m-cb__image{
            padding-top: 66%;
          }
        }
        &__content--middle{
          .m-cb__content--item--3{
            margin-bottom: 28px;
          }
          .m-cb__image{
            padding-top: 56.25%;
            &--container{
              @include xy-cell-size($size: 1 of 2, $breakpoint: s);
            }
          }
        }
        &__body {
          padding-top: 28px;
          padding-bottom: 0;
        }
      }
    }

    &--6alt-inline {
      .m-cb {
        &__body {
          padding: 24px 0px 0px;
        }

        &__content {
          &--left {
            margin-bottom: 0px;

            .m-cb {
              &__heading {
                font-size: 14px;
                line-height: 20px;
              }

              &__image {
                padding-top: 66.25%;
                border-radius: 20px;
                margin-bottom: 20px;
              }
            }
          }

          &--middle,
          &--right {
            .m-cb {
              &__heading {
                font-size: 12px;
                line-height: 20px;
              }

              &__link {
                margin-bottom: 28px;
              }

              &__image {
                border-radius: 8px;
                padding-top: 75%;
              }
            }
          }

          &--middle {
            .m-cb {
              &__heading {
                font-weight: 600;
              }
              &__image--container {
                display: none;
              }
            }
          }

          &--right {
            .m-cb {
              &__heading {
                font-weight: 700;
              }
            }
          }
        }

        &__cta {
          &--container {
            margin-top: 0px;

            a.m-cb__cta {
              width: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  .m-cb {

    &__cta--container {
      a {
        line-height: 16px
      }
    }

    .stream-container--hub.finshop &{
      .m-cb {
        &__content {
          @include xy-cell-size($size: 5.5 of 11, $breakpoint: m);
        }

        &__content--right {
          margin-left: 20px;
          @include xy-cell-size($size: 5.5 of 11, $breakpoint: m);
        }
      }
    }

    &__title, .m-yss_h_menu_header__title {
      .stream-container--shopping &,
      .stream-container--homepage &{
        @include title-font-medium;
      }
    }

    &__content{
      &--lead{
        .m-cb__image{
          border-radius: 20px;
        }
        .m-cb__heading{
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }
      }
      &--short-stack{
        .m-cb__image{
          &--container{
            width: 80px;
          }
        }
      }
    }

    &--1 {
      .m-cb {
        &__summary {
          font-size: 14px;
          line-height: 24px;
        }

        &__heading {
          font-size: 20px;
          line-height: 28px;
        }

        &__image {
          border-radius: 16px;
        }
      }
    }

    &--2 {
      .m-cb {
        &__image {
          border-radius: 16px;
        }
      }
    }

    &--3 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 16px;
            }
          }


          &--right {
            .m-cb__heading {
              @include xy-cell-size($size: 3 of 5, $breakpoint: m);
              font-size: 16px;
              line-height: 24px;
            }

            .m-cb__image {
              border-radius: 12px;

              &--container {
                @include xy-cell-size($size: 2 of 5, $breakpoint: m);
              }
            }
          }
        }
      }
    }

    &--4 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 20px;
              line-height: 28px;
            }

            .m-cb__image {
              border-radius: 16px;
            }
          }

          &--right {
            .m-cb__image {
              border-radius: 12px;
            }

            .m-cb__heading {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &__cta--container {
          position: relative;
          top: -56px;
        }
      }
    }

    &--5 {
      .m-cb {

        &__body {
          padding-bottom: 0;
        }
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 16px;
            }
          }

          &--right {
            .m-cb__image {
              border-radius: 12px;
            }
          }
        }
      }
    }

    &--6 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__link {
              .m-cb__heading {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    &--6alt {
      .m-cb {
        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 18px;
              }

              &__image {
                padding-top: 66.66%;
              }
            }
          }
          &--middle {
            .m-cb__link {
              margin-bottom: 28px;
            }
          }
        }
      }
    }

    &--g3,
    &--g2 {
      .m-cb {
        &__heading {
          font-size: 16px;
          line-height: 24px;
        }

        &__content.img-alt {
          .m-cb__heading {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    &--g2 {
      .m-cb {
        &__heading {
          font-size: 20px;
          line-height: 24px;
        }
        &__content {
          @include xy-cell-size($size: 5 of 11, $breakpoint: m);

          &--right {
            @include xy-cell-offset($n: 1 of 11, $breakpoint: auto)
          }
        }
      }
    }

    &--vn {
      .video-player,
      .m-video-wafer__player--container {
        border-radius: 16px;
      }

      .m-cb__content {
        &--left {
          .m-cb {
            &__heading {
              font-size: 18px;
              line-height: 24px;
            }

            &__image {
              border-radius: 16px;
            }
          }
        }
      }
    }

    &--ss {
      .m-yss {
        &__vs {
          display: block;
          .m-yss__vs--link{
            display: block;
          }
        }
        &_h_menu {
          margin-bottom: 0px !important;
          &--select_tabs {
            display: none;
          }
        }
        &__h {
          &-scores-container {
            display: none;
            a{
              display: none !important;
            }
            .glide__arrow--right,.glide__arrow--left{
              display: none !important;
            }
          }
        }
      }

      .m-cb{
        &__content {
          &--left, &--left_v2 {
            .m-cb {
              &__image{
                padding-top: 75%;
              }
              &__heading {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          &--right {
            margin-bottom: 0;
            .m-cb {
              &__image{
                padding-top: 56.25%;
                margin-bottom: 14px;
              }
            }
            .hide-for-m-break {
              display: none;
            }
          }
          &--right_v2 {
            .m-cb {
              &__image--container {
                display: none;
              }
              &__image {
                padding-top: 100%;
                margin-bottom: 0px;
              }
            }
          }
        }

        &__last_layer {
          display: block;
          .m-cb {
            &__link {
              display: block;
            }
            &__content {
              &--right {
                .m-cb {
                  &__image{
                    padding-top: 100%;
                    margin-bottom: 0px;
                    &--container{
                      width: 72px;
                    }
                  }
                }
              }
            }
          }
        }
        &__mid_layer {
          .m-cb__cta--container {
            display: none;
          }
        }
      }
    }

    &--games{
      .m-cb__content{
        &--left{
          @include xy-cell-size($size: 5 of 11, $breakpoint:m);
          .m-cb__content--wrap{
            flex-direction: row;
          }
          .m-cb__image{
            padding-top: 56.25%;
          }
          .m-cb__playBtn{
            &--inner{
              height: 36px;
              width: 59px;
            }
            &--container {
              justify-content: flex-end;
              text-align: end;
              align-self: flex-start;
            }
          }
          .m-cb__summary{
            margin-bottom: 0;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
          }
          .m-cb__heading {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 12px;
          }
        }

        &--left.trivia-games {
          @include xy-cell-size($size: 4 of 7, $breakpoint:m);
        }

        &--right.trivia-games {
          @include xy-cell-size($size: 3 of 7, $breakpoint:m);
        }
        &--right{
          .m-cb__cta{
            padding: 11px 20px;
          }
          @include xy-cell-size($size: 6 of 11, $breakpoint:m);
          .m-cb__item{
            @include xy-cell-size($size: 1 of 2, $breakpoint: m);
          }
        }

        &--trivia-games {
          @include xy-cell-size($size: 4 of 11, $breakpoint:m);
        }
      }
    }

    &--4inline {
      .m-cb {
        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
              }
            }
          }

        }
      }
    }

    &--3inline{
      .m-cb__content{
        &--left{
          .m-cb__heading{

            font-size: 18px;
            font-weight: 700;
            line-height: 24px;

          }
          .m-cb__image{
            padding-top: 56.25%;
          }
        }
      }
    }

    &--food {
      .m-cb {

        &__content {
          &--left {
            @include xy-cell-size($size: 3  of 7, $breakpoint: m);

            .m-cb__heading {
              font-size: 18px;
              line-height: 24px;
            }
          }

          &--middle {
            @include xy-cell-size($size: 4  of 7, $breakpoint: m);
            .m-cb__link{
              display: block;
              margin-bottom: 36px;

              &--3, &--4{
                margin-bottom: 0;
              }

              .m-cb__heading{
                padding-right: 10px;
                margin-bottom: 0;
                font-weight: 700;
              }
              .m-cb__image{
                padding-top: 75%;
                margin-bottom: 16px;
                border-radius: 12px;
                &--container {
                  display: flex;
                }
              }
            }

            .m-cb__cta{
              margin-top: 28px;
            }
          }
        }
      }
    }

    &--9 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 18px;
              line-height: 24px;
            }
          }
          &--middle {
            @include xy-cell-size($size: 3 of 7, $breakpoint: auto);
            .m-cb__link--2{
              margin-bottom: 0;
            }
          }

          &--right {
            @include xy-cell-size($size: 4 of 7, $breakpoint: auto);
            .m-cb__image {
              padding-top: 72px;
              border-radius: 8px;
              max-width: 72px;
            }
            .m-cb__link{
              display: block;
            }
            .m-cb__link--5, .m-cb__link--6{
              display: none;
            }
          }
        }
      }
    }

    &--6alt-inline {
      .m-cb {
        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 18px;
                line-height: 24px;
              }

              &__image {
                border-radius: 12px;
              }
            }
          }

          &--middle,
          &--right {
            .m-cb {
              &__heading {
                font-size: 13px;
              }

              &__link {
                margin-bottom: 28px;
              }
            }
          }
        }
      }
    }

    &--bn{

      .business{
        .m-cb__content--right{
          .m-cb{
            &__heading {
              margin-bottom: 0px;
            }
            &__image--container {
              display: block;
            }
            &__cta {
              padding: 11px 20px;
              &--container {
                margin-top: 28px;
              }
            }
          }
        }
      }

      .politics{
        .DesktopTitle{
          display: block;
        }
        .MobileTitle{
          display: none;
        }
        &--left{
          @include xy-cell-size($size: 3 of 7, $breakpoint: m);
        }
        .m-cb__content{
          &--left{
            .m-cb{
              &__image {
                padding-top: 75%;
                margin-bottom: 16px;
              }
              &__heading {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
              }
            }
          }
          &--right{
            .m-cb{
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
              }

              &__image--container {
                display: block;
                max-width: 72px;
              }
              &__image {
                margin-bottom: 0;
                border-radius: 8px;
                padding-top: 100%;
              }
              &__cta {
                padding: 11px 20px;
                border-radius: 48px;
              }
            }
          }
        }
      }
    }
    &--vna {
      .m-cb {
        &__content {
          &--right{
            .m-cb{
              &__content--list{

                .m-cb__item {
                  &.m-cb__item {
                    @include set-vna-bottom-margins(24px);
                    @include reset-vna-visibility;
                    // set visible conditions
                    &--2 ,&--4, &--6{
                      visibility: hidden;
                      .m-cb__link{
                        visibility: hidden;
                      }
                      .mpu--small &{
                        visibility: visible;
                        .m-cb__link{
                          visibility: visible;
                        }
                      }
                    }
                    &--5, &--6{
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &--4inline-rounded {
      .m-cb {
        &__heading {
          font-size: 16px;
          text-align: center;
        }

        &__summary {
          font-size: 13px;
          text-align: center;
        }

        &__playBtn--container {
          justify-content: center;
        }

        &__cta {
          padding: 11px 20px;
        }

        &--content-section {
          margin-right: 0px;
        }

        &__content--item {
          padding: 40px 16px 32px;
          margin-bottom: 28px;
        }

        &__image {
          width: 52px;
          min-width: 52px;
          padding-top: 52px;
          margin-bottom: 28px;
        }
      }
    }

    &--mc{
      .m-cb {
        &__finance-markets {
          .finance-market {
            display: block;

            .tab-wrap-link, #markets-autocomplete, .search-wrapper, .market-link .link {
              display: block;
            }

            .tabs-option {
              .tab {
                a {
                  display: block;
                }
              }
            }
          }

        }
        &__content{
          &--lead{
            @include xy-cell-size($size: 3 of 7, $breakpoint: m);
            .m-cb__image{
              padding-top: 100%;
              border-radius: 20px;
            }
            .m-cb__heading{
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
              line-height: 24px;
            }
          }
          &--short-stack{
            .m-cb__content--item:nth-of-type(3){
              margin-bottom: 0;
            }
            .m-cb__image{
              &--container{
                display: block;
              }
            }
          }
        }
      }
    }

    &--7{
      .m-cb {
        &__content--lead{
          .m-cb__image{
            padding-top: 75%;
          }
        }
        &__content--middle{
          .m-cb__content--item{
            &--1{
              margin-bottom: 28px;
            }
            &--2, &--3{
              display: none;
              a{
                display: none;
              }
            }
          }
          .m-cb__heading {
            font-size: 14px;
            font-weight: 700;
            &--container{
              order: 2;
            }
          }
          .m-cb__image{
            padding-top: 100%;
            &--container{
              @include xy-cell-size($size: 1 of 1, $breakpoint: m);
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)) {
  .m-cb {

    .stream-container--personal_finance &{

      .m-cb__title {
        font-size: 20px;
        line-height: 1.6;
        font-weight: 800;
        letter-spacing: -0.6px;
      }
    }

    &__title, .m-yss_h_menu_header__title {
      .stream-container--shopping &,
      .stream-container--homepage &{
        @include title-font-large;
      }
    }

    &--ec, &--tc {
      .m-cb--elections, .m-cb--trueCrime{
        .m-cb__content{
          &--lead{
            @include xy-cell-size($size: 3 of 5, $breakpoint: auto);
          }
          &--short-stack{
            @include xy-cell-size($size: 2 of 5, $breakpoint: auto);
          }
        }
      }
      .m-cb--content{
        .m-cb__content{
          &--lead{
            @include xy-cell-size($size: 2 of 5, $breakpoint: auto);
            .m-cb__image{
              padding-top: 100%;
            }
            .m-cb__heading{
              font-size: 14px;
            }
          }
          &--short-stack{
            @include xy-cell-size($size: 3 of 5, $breakpoint: auto);
            .m-cb__image{
              &--container {
                display: block;
              }
            }
          }
        }
      }
    }
    &--3 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 18px;
            }
          }

          &--right {
            .m-cb__heading {
              @include xy-cell-size($size: 3 of 5, $breakpoint: l);
              font-size: 14px;
              line-height: 20px;
            }

            .m-cb__image--container {
              @include xy-cell-size($size: 2 of 5, $breakpoint: l);
            }
          }
        }
      }
    }

    &--4 {
      .m-cb {
        &__content {
          &--right {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        &__body {
          padding-bottom: 3px;
        }

        &__cta--container {
          position: static;
        }
      }
    }

    &--5 {
      .m-cb {
        &__body {
          padding-bottom: 0;
        }
      }
    }

    &--6{
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 18px;
              line-height: 24px;
            }
          }
          &--middle {
            .m-cb__heading {
              font-size: 14px;
              line-height: 20px;
            }
          }
          &--right {
            .m-cb__image {
              margin-bottom: 0;
              border-radius: 8px;
              &--container {
                display: block;
                width: 72px;
                flex-shrink: 0;
              }
              &--cell{
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    &--g2,
    &--g3 {
      .m-cb {
        &__heading {
          font-size: 14px;
          line-height: 20px;
        }

        &__content.img-alt {
          .m-cb__image {
            padding-top: 100%;
          }
        }

        &__image {
          padding-top: 72%;
        }
      }
    }

    &--g2 {
      .m-cb {
        &__heading {
          font-size: 20px;
          line-height: 24px;
        }
        &__content {
          @include xy-cell-size($size: 5 of 10, $breakpoint: l);

          &--right {
            @include xy-cell-offset($n: 0, $breakpoint: l)
          }
        }

        &__image {
          padding-top: 56.25%;
        }
      }
    }

    &--9 {
      .m-cb {
        &__content {
          &--middle {
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);
            .m-cb__link {
              .m-cb__heading {
                font-size: 14px;
              }
            }
          }

          &--right {
            @include xy-cell-size($size: 3 of 6, $breakpoint: auto);
          }
        }
      }
    }

    &--games{
      .m-cb__content{
        &--left{
          @include xy-cell-size($size: 4 of 10, $breakpoint: l);
          .m-cb__heading {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
          }
          .m-cb__summary{
            font-size: 13px;
            line-height: 20px;
            font-weight: 500;
          }
          .m-cb__image{
            padding-top: 75%;
            border-radius: 20px;
          }
        }
        &--left.trivia-games {
          @include xy-cell-size($size: 4 of 7, $breakpoint:l);
        }
        &--right.trivia-games {
          @include xy-cell-size($size: 3 of 7, $breakpoint:l);

          .m-cb__carousel--slides {
            flex-direction: column;
            .m-cb__item:nth-child(n+4) {
              display: none;
            }
          }

          .m-cb__item{
            .m-cb__link {
              width: 95%;
              .flex-child-shrink {
                width: 95%;
              }
            }
          }
        }
        &--right{
          .m-cb__subheading {
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            margin-top: -1px;
          }
          @include xy-cell-size($size: 6 of 10, $breakpoint: l);
          .m-cb__item{
            @include xy-cell-size($size: 1 of 2, $breakpoint: l);
            .m-cb__image{
              &--cell{
                margin-right: 16px;
              }
            }
          }
        }
        &--trivia-games {
          @include xy-cell-size($size: 3 of 10, $breakpoint:l);
        }
      }
    }

    &--ss, &--vn {
      .m-cb__content {
        &--right, &--right_v2 {
          .m-cb {
            &__heading {
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
      }
    }

    &--ss {
      .m-cb__extra_layer {
        display: block;
        .m-cb__link {
          display: block;
        }
        .m-cb__content {
          &--right {
            .m-cb__link {
              div.grid-x {
                margin-bottom: 32px;
              }
            }
            .m-cb__content--item {
              &--6 {
                .m-cb__link {
                  div.grid-x {
                    margin-bottom: 28px;
                  }
                }
              }
              &--5 {
                .m-cb__link {
                  div.grid-x {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
      .m-cb__mid_layer, .m-cb__last_layer {
        display: none;
        .m-cb__link {
          display: none;
        }
      }

      .m-cb__content {
        &--left, &--left_v2 {
          .m-cb {
            &__image {
              padding-top: 56.25%;
            }
          }
        }

        &--right, &--right_v2 {
          .m-cb {
            &__image--container {
              display: none;
            }
            &__image{
              margin-bottom: 0px;
            }
          }
          .hide-for-m-break {
            display: flex;
          }
        }
      }
    }

    &--4inline {
      .m-cb__content {
        &--left {
          .m-cb {
            &__heading {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }

    &--food {
      .m-cb__content {
        &--left {
          @include xy-cell-size($size: 3  of 7, $breakpoint: l);
        }

        &--middle {
          @include xy-cell-size($size: 4  of 7, $breakpoint: l);
          .m-cb__link{
            &--1, &--2{
              margin-bottom: 32px;
            }
          }

        }
      }
    }

    &--bn{
      .finance-market{
        .maas-item{
          margin-right: 0;
          margin-left: 0;
        }
        #yfm-markets{
          .finance-market{
            margin-bottom: 0;
          }
        }
      }
      .business{
        padding-left: 0;
        .m-cb__content--right{
          .m-cb {
            &__content--item {
              margin-top: 0;
              margin-bottom: 0;
            }
            &__content--item:nth-child(n+2) {
              margin-top: 32px;
            }

            &__cta--container {
              a {
                margin-top: 8px;
              }
            }
          }
        }
      }

      .politics{
        &--left{
          @include xy-cell-size($size: 2 of 4, $breakpoint: l);
        }
        .m-cb__content{
          &--left{
            .m-cb{
              &__heading {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          &--right{
            .m-cb{

              &__content--item:nth-child(n+2) {
                margin-top: 32px;
              }

              &__content--item {
                margin-bottom: 0;
                a.m-cb__link {
                  .m-cb__heading {
                    margin-bottom: 0;
                  }
                }
              }
              &__heading {
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
              }
              &__cta--container {
                margin-top: 28px;

              }
            }
          }
        }
      }
    }
    &--vna {
      .stream-container--homepage & {
        padding-bottom: 0;
        margin-bottom: 32px;
      }
      .m-cb {
        &__content {
          &--right{
            .m-cb{
              &__content--list{

                .m-cb__item {
                  &.m-cb__item {
                    @include reset-vna-visibility;
                    // set visible conditions
                    &--2 ,&--4{
                      visibility: hidden;
                      .m-cb__link{
                        visibility: hidden;
                      }
                      .mpu--small &{
                        visibility: visible;
                        .m-cb__link{
                          visibility: visible;
                        }
                      }
                    }
                  }

                  .m-cb__cta--container{
                    justify-content: flex-end;
                    margin-top: 28px;
                  }
                }

              }
            }
          }
        }
      }
    }

    &--6alt {
      .m-cb {
        &__content {
          &--right {
            .m-cb {
              &__image--container {
                display: block;
                width: 72px;
                flex-shrink: 0;
              }
              &__image {
                border-radius: 8px;
                padding-top: 72px;
              }
            }
          }
        }
      }
    }

    &--mc{
      .m-cb {
        &__content {
          &--lead {
            @include xy-cell-size($size: 3 of 6, $breakpoint: l);

            .m-cb__heading {
              font-size: 18px;
            }
          }
        }
      }
    }

    &--7{
      .m-cb {
        &__content--lead{
          .m-cb__image{
            padding-top: 66%;
          }
        }
        &__content--middle{
          .m-cb__heading {
            &--container{
              order: 2;
            }
          }
          .m-cb__image{
            padding-top: 75%;
            &--container{
              @include xy-cell-size($size: 1 of 1, $breakpoint: l);
            }
          }
        }
        &__content--right{
          .m-cb__content--item{
            &--3{
              margin-bottom: 28px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .m-cb {

    &__content{
      &--short-stack{
        .m-cb__content--item{
          margin-bottom: 44px;
        }
        .m-cb__heading{
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
        }
      }
    }

    &--ec{
      .m-cb--content{
        .m-cb__content{
          &--lead{
            .m-cb__heading{
              font-size: 16px;
            }
          }
        }
      }
    }

    &--ss , &--vn  {
      .m-cb__content {
        &--right, &--right_v2 {
          .m-cb__heading {
            font-size: 14px;
          }

          .m-cb__image--container {
            width: 80px;
          }
        }
      }
    }
    &--vn {
      .m-cb__content {
        &--right {
          a.m-cb__link {
            div.grid-x {
              margin-bottom: 36px;
            }
          }
        }
      }
    }
    &--games{
      .m-cb__content{
        &--left.trivia-games {
          @include xy-cell-size($size: 4 of 7, $breakpoint: xl);
        }
        &--left{
          @include xy-cell-size($size: 4 of 10, $breakpoint: xxl);
          .m-cb__image{
            padding-top: 56.25%;
            border-radius: 20px;
          }
        }
        &--right.trivia-games {
          @include xy-cell-size($size: 3 of 7, $breakpoint: xl);
        }
        &--right{
          @include xy-cell-size($size: 6 of 10, $breakpoint: xl);
          .m-cb__subheading{
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-top: -3px;
          }
          .m-cb__cta--container{
            margin-top: -8px;
          }
          .m-cb__item{
            margin-bottom: 36px;
            @include xy-cell-size($size: 1 of 2, $breakpoint: xl);
            .m-cb__heading{
              font-size: 13px;
              line-height: 16px;
              font-weight: 600;
            }
            .m-cb__image {
              padding-top: 56.25%;
              &--container{
                width: 120px;
              }
              &--cell{
                max-width: 120px;
                margin-right: 20px;
              }
            }
          }
        }

      }
    }

    &--ss {
      .m-cb__content {
        &--left, &--left_v2 {
          .m-cb {
            &__heading {
              font-size: 18px;
            }
            &__image {
              padding-top: 75%;
              border-radius: 20px;
            }
          }
        }

        &--right, &--right_v2 {
          .m-cb {
            &__link {
              .m-cb__heading {
                font-size: 14px;
              }
              &:last-child{
                margin-bottom: 0;
              }
              div.grid-x {
                margin-bottom: 44px;
              }
            }
            &__image--container{
              display: flex;
              max-width: 80px;
            }
            &__image {
              padding-top: 100%;
              margin-bottom: 0px;
            }
            &__heading {
              margin-bottom: 0px;
            }
          }
        }
      }

      .m-cb__extra_layer {
        .m-cb__content {
          &--right {
            .m-cb__link {
              div.grid-x {
                margin-bottom: 44px;
              }
            }
            .m-cb__content--item{
              &--6 {
                .m-cb__link {
                  div.grid-x {
                    margin-bottom: 28px;
                  }
                }
              }
              &--5 {
                .m-cb__link {
                  div.grid-x {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &--6{
      .m-cb__content{
        &--left {
          .m-cb {
            &__image {
              border-radius: 20px;
            }
          }
        }

        &--middle {
          .m-cb__link {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }
            .m-cb__image {
              border-radius: 16px;
            }
          }
        }
        &--right {
          .m-cb__heading {
            font-size: 14px;
            line-height: 20px;
          }
          .m-cb__link {
            margin-bottom: 44px;
          }
          .m-cb__image--container {
            width: 80px;
          }
        }
      }
    }

    &--4 {
      .m-cb {
        &__body {
          padding-bottom: 24px;
        }
      }
    }

    &--5 {
      .m-cb {
        &__body {
          padding-bottom: 0;
        }
        &__content {
          &--right {
            .m-cb__content--item:nth-child(3) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &--4inline {
      .m-cb__content {
        &--left {
          .m-cb {
            &__heading {
              font-size: 18px;
              line-height: 24px;
            }

            &__image {
              border-radius: 20px;
            }
          }
        }

        &--right {
          .m-cb {
            &__heading {
              font-size: 14px;
              line-height: 20px;
            }

            &__image {
              border-radius: 12px;
            }
          }
        }
      }
    }

    &--food {
      .m-cb__content {
        &--left {
          @include xy-cell-size($size: 3  of 7, $breakpoint: xl);
          .m-cb {
            &__heading {
              font-size: 18px;
              line-height: 24px;
            }

            &__image {
              border-radius: 20px;
            }
          }
        }

        &--middle {
          @include xy-cell-size($size: 4  of 7, $breakpoint: xl);
          .m-cb {
            &__link{
              &--1, &--2{
                margin-bottom: 44px;
              }
              .m-cb__heading {
                font-size: 14px;
                line-height: 20px;
              }
            }

            &__cta {
              margin-top: 4px;
            }
          }
        }
      }
    }

    &--9 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 20px;
            }
          }

          &--middle {
            @include xy-cell-size($size: 4 of 6, $breakpoint: auto);
            .m-cb__link {
              .m-cb__heading {
                font-size: 13px;
              }
            }
            .m-cb__link--1,.m-cb__link--2{
              margin-bottom: 44px;
            }
            .m-cb__link--3, .m-cb__link--4{
              display: block;
              margin-bottom: 0;
            }
          }

          &--right {
            @include xy-cell-size($size: 2 of 6, $breakpoint: auto);

            .m-cb__link {
              margin-bottom: 36px;

              &--5 {
                display: block;
                margin-bottom: 28px;
              }
              &--6{
                display: none;
              }
              .m-cb__heading {
                font-size: 14px;
              }
            }

            .m-cb__link--1, .m-cb__link--2 {
              display: none;
            }
          }
        }
      }
    }

    &--6alt-inline {
      .m-cb {
        &__content {
          &--left {
            .m-cb {
              &__heading {
                font-size: 20px;
                line-height: 28px;
              }

              &__image {
                border-radius: 20px;
                padding-top: 56.25%;
              }
            }
          }

          &--middle {
            .m-cb {
              &__link {
                margin-bottom: 36px;
              }

              &__heading {
                font-size: 14px;
              }
            }
          }

          &--right {
            .m-cb {
              &__heading {
                font-size: 14px;
              }

              &__image {
                border-radius: 12px;
              }
            }
          }
        }
      }
    }

    &--bn{
      .finance-market {
        @include xy-cell-size($size: 3 of 5, $breakpoint: auto);
        .finance-market{
          width: 100%;
        }
        display: block;
        .tab-wrap-link, #markets-autocomplete, .search-wrapper, .market-link .link{
          display: block;
        }
        .tabs-option{
          .tab{
            a{
              display: block;
            }
          }
        }
      }
      .business{
        @include xy-cell-size($size: 2 of 5, $breakpoint: auto);
        .m-cb{
          &__finance-banner{
            display: none;
            button {
              display: none;
            }
          }
        }
        .m-cb__content--right{
          .m-cb{
            &__content--item {
              .m-cb__heading {
                font-size: 14px;
                font-style: normal;
                font-family: $font-family-poppins;
                line-height: 20px;
                font-weight: 600;
              }
            }
            &__content--item:nth-child(n+2) {
              margin-top: 36px;
            }
            &__cta--container {
              margin-top: 20px;

            }
            &__image--cell {
              display: none;
            }
          }
        }
      }

      .politics{
        &--left{
          @include xy-cell-size($size: 3 of 5, $breakpoint: auto);
        }
        .m-cb__content{
          &--left{
            .m-cb{
              &__heading {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
              }
              &__image {
                padding-top: 75%;
                border-radius: 20px;
              }
            }
          }

          &--right{
            .m-cb{

              @include business-politics-style-xl;

              &__content--item {
                .m-cb__heading  {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
              &__image--container {
                display: none;
              }
            }
          }
        }
      }
    }

    &--vna {
      .m-cb {
        &__content {
          &--right{
            .m-cb{
              &__content--list{
                .m-cb__item {
                  .m-cb__heading{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 20px;
                  }
                  .m-cb__image{
                    margin-bottom: 0;
                    border-radius: 8px;
                    &--container{
                      width: 80px;
                      flex-shrink: 0;
                    }
                    &--cell{
                      display: flex;
                      justify-content: flex-end;
                    }
                  }
                  &.m-cb__item {
                    @include set-vna-bottom-margins(36px);
                    @include reset-vna-visibility;
                    // set visible conditions

                    &--4{
                      .isABP:not(.mpu--small,.mpu--stack) &{
                        visibility: hidden;
                        .m-cb__link{
                          visibility: hidden;
                        }
                      }
                    }
                    &--2{
                      visibility: hidden;
                      .m-cb__link{
                        visibility: hidden;
                      }
                      .mpu--small &{
                        visibility: visible;
                        .m-cb__link{
                          visibility: visible;
                        }
                      }
                    }
                    &--5, &--6{
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &--3inline {
      .m-cb__content {
        &--left {
          .m-cb {
            &__image {
              border-radius: 20px;
            }
          }
        }
        &--right {
          .m-cb {
            &__heading {
              margin-bottom: 0;
              font-size: 14px;
            }
            &__playBtn {
              font-size: 12px;
              &--container {
                width: auto;
              }
            }
          }
        }
      }
    }

    &--4inline-rounded {
      .m-cb {
        &__heading {
          font-size: 18px;
          line-height: 24px;
        }

        &__summary {
          font-size: 14px;
        }
      }
    }

    &--6alt {
      .m-cb {
        &__content {

          &--left {
            .m-cb {
              &__heading {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          &--middle {
            .m-cb {
              &__heading {
                font-size: 13px;
              }

              &__link {
                margin-bottom: 32px;
              }
            }
          }

          &--right {
            .m-cb {
              &__heading {
                font-size: 14px;
              }

              &__link {
                margin-bottom: 44px;
              }
            }
          }
        }
      }
    }

    &--mc{
      .m-cb {
        &__content {
          &--lead {
            @include xy-cell-size($size: 3 of 6, $breakpoint: xl);
            .m-cb__image{
              padding-top: 75%;
            }
            .m-cb__heading {
              font-size: 20px;
            }
          }
        }
      }
    }

    &--7{
      .m-cb {
        &__content--middle{
          .m-cb__content--item{
            &--1{
              margin-bottom: 32px;
            }
            &--2, &--3{
              display: block;
              a{
                display: flex;
              }
            }
          }
          .m-cb__heading {
            font-size: 13px;
            font-weight: 600;
            &--container{
              order: 0;
            }
          }
          .m-cb__image{
            padding-top: 100%;
            &--container{
              width: 80px;
              margin-bottom: 0;
            }
          }
        }
        &__content--right{
          .m-cb__content--item{
            &--3{
              margin-bottom: 32px;
            }
            &--5{
              margin-bottom: 32px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .m-cb {
    &__cta {
      &--container {
        a.m-cb__cta {
          padding: 13px 20px;
        }
      }
    }

    &__grid_image {
      img {
        border-radius: 20px;
      }
    }

    &--1 {
      .m-cb {
        &__image {
          border-radius: 20px;
        }

        &__heading {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    &--2 {
      .m-cb {
        &__image {
          border-radius: 20px;
        }
      }
    }

    &--3 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__heading {
              font-size: 20px;
              line-height: 28px;
            }

            .m-cb__image {
              border-radius: 20px;
            }
          }

          &--right {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }

            .m-cb__image {
              border-radius: 16px;
            }
          }
        }
      }
    }

    &--3inline {
      .m-cb__content {
        &--left {
          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }
          .m-cb__image {
            border-radius: 20px;
          }
        }
        &--right {
          .m-cb__image {
            border-radius: 12px;
          }

          .m-cb__heading {
            font-weight: 600;
          }
        }
      }
    }

    &--4 {
      .m-cb {
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 20px;
            }

            .m-cb__heading {
              font-size: 24px;
              line-height: 32px;
            }
          }

          &--right {
            .m-cb__heading {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    &--4inline {
      .m-cb__content {
        &--left {
          .m-cb__image {
            border-radius: 20px;
          }

          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }
        }
        &--right {
          .m-cb__image {
            border-radius: 12px;
          }
          .m-cb__heading {
            font-weight: 600;
          }
        }
      }
    }

    &--ss {
      .m-cb__content {
        &--left, &--left_v2 {
          .m-cb {
            &__link {
              .m-cb__heading {
                font-size: 20px;
                line-height: 28px;
              }
              .m-cb__image {
                border-radius: 20px;
                padding-top: 56.25%;
              }
            }
          }
        }
      }
    }

    &--5 {
      .m-cb {
        &__body {
          padding-bottom: 0;
        }
        &__content {
          &--left {
            .m-cb__image {
              border-radius: 20px;
            }
          }

          &--right {
            .m-cb__image {
              border-radius: 16px;
            }
          }
        }
      }
    }

    &--6{
      .m-cb__content{
        &--left {
          .m-cb {
            &__image {
              border-radius: 20px;
            }

            &__link {
              .m-cb__heading {
                font-size: 20px;
                line-height: 28px;
              }
            }
          }
        }
        &--middle {
          .m-cb__image {
            padding-top: 75%;
            border-radius: 16px;
          }
          .m-cb__heading {
            font-weight: 700;
            line-height: 24px;
          }
        }

        &--right {
          .m-cb__cta{
            padding: 13px 20px;
          }
        }
      }
    }

    &--9 {
      .m-cb__content {
        &--left {
          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }

          .m-cb__image {
            border-radius: 20px;
          }
        }

        &--middle {
          .m-cb__link {
            &--1, &--2 {
              margin-bottom: 52px;
            }
            .m-cb__heading {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        &--right {
          .m-cb__cta{
            padding: 13px 20px;
          }
          .m-cb__link--5{
            display: block;
            margin-bottom: 36px;
          }
          .m-cb__link--6{
            display: block;
            margin-bottom: 28px;
          }
          .m-cb__link--1, .m-cb__link--2{
            display: none;
          }
        }
      }
    }

    &--6alt-inline {
      .m-cb {
        &__content {
          &--right {
            .m-cb {
              &__heading {
                font-size: 16px;
                line-height: 24px;
              }

              &__image {
                border-radius: 16px;
              }
            }
          }
        }
      }
    }

    &--g3,
    &--g2 {
      .m-cb {
        &__heading {
          font-size: 18px;
          line-height: 24px;
        }

        &__image {
          border-radius: 16px;
        }

        &__content.img-alt {
          .m-cb__heading {
            font-size: 16px;
          }
        }
      }
    }

    &--g2 {
      .m-cb {
        &__heading {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &--vn {
      .video-player,
      .m-video-wafer__player--container {
        border-radius: 20px;
      }

      .m-cb__content {
        &--left {
          .m-cb {
            &__heading {
              font-size: 20px;
              line-height: 28px;
            }

            &__image {
              border-radius: 20px;
            }
          }
        }

        &--right {
          .m-cb {
            &__heading {
              margin-bottom: 0;
            }
          }

          a.m-cb__link {
            div.grid-x {
              margin-bottom: 44px;
            }
          }
        }

      }
    }

    &--games{
      .m-cb__content{
        &--left {
          .m-cb__heading {
            font-size: 20px;
            line-height: 28px;
          }
          .m-cb__summary{
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }
        &--right{
          .m-cb__cta{
            padding: 13px 20px;
            height: 44px;
            &--container{
              margin-top: -8px;
            }
          }
          .m-cb__item{
            .m-cb__playBtn--container {
              margin-top: 16px;
              display: flex !important;
            }
          }
        }
        &--right.trivia-games {
          .m-cb__carousel--slides {
            flex-direction: column;
          }
        }
      }
    }

    &--food {
      .m-cb__content {
        &--left {
          @include xy-cell-size($size: 3  of 7, $breakpoint: xxl);
          .m-cb {
            &__image{
              border-radius: 20px;
            }

            &__link {
              .m-cb__heading {
                font-size: 20px;
                line-height: 28px;
              }
            }
          }
        }
        &--middle {
          @include xy-cell-size($size: 4  of 7, $breakpoint: xxl);

          .m-cb {
            &__link {
              .m-cb__heading {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    &--bn{
      .business{
        .m-cb__content--right{
          .m-cb{

            @include business-politics-style-xxl;
            &__image--container {
              display: none;
            }
          }
        }

        .m-cb__cta {
          padding: 13px 20px;
          &--container {
            margin-top: 28px;
          }
        }
      }

      .politics{
        &--left{
          @include xy-cell-size($size: 3 of 5, $breakpoint: m);
        }
        .m-cb__content{
          &--left{
            .m-cb{
              &__link {
                .m-cb__image {
                  margin-bottom: 20px;
                }
              }
              &__heading {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
              }
              &__image {
                padding-top: 66%;
              }
            }
          }

          &--right{
            .m-cb{
              &__content--item {
                margin-bottom: 0;
                margin-top: 0;
              }
              &__content--item:nth-child(n+2) {
                margin-top: 44px;
              }

              &__cta {
                padding: 13px 20px;
                &--container {
                  margin-top: 28px;
                  a {
                    margin-top: 0;
                  }
                }
              }
              &__heading {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
              }
              &__image--container {
                display: none;
              }
            }
          }
        }
      }
    }
    &--vna {
      .m-cb {
        &__content {
          &--right{
            .m-cb{
              &__content {
                &--list {
                  .m-cb__item {
                    .m-cb__heading {
                      font-size: 14px;
                      line-height: 20px;
                    }

                    &.m-cb__item {
                      @include set-vna-bottom-margins(40px);
                      @include reset-vna-visibility;
                      // set visible conditions
                      &--2{
                        visibility: hidden;
                        .m-cb__link{
                          visibility: hidden;
                        }
                        .mpu--small &,
                        .mpu--stack:not(.isABP) &{
                          visibility: visible;
                          .m-cb__link{
                            visibility: visible;
                          }
                        }
                      }
                      &--5, &--6{
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &--4inline-rounded {
      .m-cb {
        &__cta {
          padding: 13px 20px;
          height: 44px;
        }

        &__content--item {

          &--2,
          &--3 {
            &.cell {
              margin-left: 20px;
              margin-right: 20px;
            }
          }

          &--1 {
            &.cell {
              margin-right: 20px;
            }
          }

          &--4 {
            &.cell {
              margin-left: 20px;
            }
          }
        }

        &__image {
          width: 60px;
          min-width: 60px;
          padding-top: 60px;
        }

        &__playBtn {
          font-size: 12px;
          &--inner {
            padding: 11.5px 16px;
          }
        }
      }
    }

    &--6alt {
      .m-cb {
        &__content {

          &--left {
            .m-cb {
              &__heading {
                font-size: 20px;
                line-height: 28px;
              }
            }
          }

          &--middle {
            .m-cb {
              &__link {
                .m-cb__heading {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
          }

          &--right {
            .m-cb {
              &__heading {
                font-size: 14px;
              }
            }
          }
        }

        &__cta {
          &--container {
            margin-top: 4px;
          }
        }
      }
    }
    &--mc{
      .m-cb {
        &__content {
          &--lead {
            @include xy-cell-size($size: 3 of 6, $breakpoint: xxl);

            .m-cb__heading {
              font-size: 18px;
            }
          }
        }
      }
    }

    &--ec {
      .m-cb--content {
        .m-cb__content--lead {
          .m-cb__image {
            padding-top: 75%;
          }
        }
      }
    }
  }
}
